import React, { useEffect, useState } from "react";
import { EditImage } from "./ImageEdit";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import { GET_SINGLE_SPECIES } from "./Species";
import { useParams, useSearchParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";


import { Button, ButtonLink } from "./button";

const ImageDescription = styled.div`
    opacity: 0.5;
    background-color: black;
    color: white;
    padding: 10px;
    font-size: 13px;
`;

export const ImageFill = ({ speciesId, image, crop, ruler, arrow, showNext, showPrev }) => {
    const [showEditOptions, setShowEditOptions] = React.useState(false);
    const navigate = useNavigate();
    useEffect(() => {
      const upHandler = (e) => {
        const key = e.key;
        if (key === 'ArrowLeft') {
          showPrev();
        } else if (key === 'ArrowRight') {
          showNext();
        } else if (key === 'Escape') {
          navigate('/species/' + speciesId)
        }
      };
      window.addEventListener('keyup', upHandler);
  
      return () => {
        window.removeEventListener('keyup', upHandler);
      };
    }, [image.id]);

    return (
      <div style={{
        position: "fixed",
        zIndex: "4",
        top: "0",
        left: "0",
        width: "100vw",
        height: "100vh",
        backgroundImage: `url(${image.url1024})`,
        backgroundSize: "contain",
        backgroundPosition: "top",
        backgroundRepeat: "no-repeat",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
      }}>
        <div style={{
        position: "fixed",
        left: "0",
        top: "0",
        height: "100vh",
        width: "30vw",
        zIndex: "100",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "left",
        }} onClick={showPrev}>
          <span className="material-symbols-outlined" style={{
            fontSize: "100px",
          }}>
            chevron_left
          </span>
        </div>
        <div style={{
          position: "fixed",
          right: "0",
          top: "0",
          height: "100vh",
          width: "30vw",
          zIndex: "100",
          cursor: "pointer",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "right",
        }} onClick={showNext}>
          <span className="material-symbols-outlined" style={{
            fontSize: "100px",
            textAlign: "right",
          }}>
            chevron_right
          </span>
        </div>
        <div style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          zIndex: "101",
        }}>
          <Link to={`/species/${speciesId}`}><Button>Close</Button></Link>
          <Button onClick={() => setShowEditOptions(true)}>edit image</Button>
        </div>
        {showEditOptions && <div style={{
          position: "fixed",
          top: "0",
          left: "0",
          backgroundColor: "white",
          width: "100vw",
          height: "100vh",
          zIndex: "1000",
          overflow: "scroll",
        }}>
          <EditImage image_id={image.id} close={() => setShowEditOptions(false)} />
        </div>}
        <ImageDescription>{image.copyright}</ImageDescription>
        <ImageDescription>{image.description}</ImageDescription>
      </div>
    );
}


export const ImageFillViewer = ({ close }) => {
    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const { loading, error, data } = useQuery(GET_SINGLE_SPECIES, { variables: {"speciesID": id} });

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;
    const idx = Number(searchParams.get('i'))
    if (idx === undefined) {
        setSearchParams({i: 0});
        return null;
    }
    const images = data.species.images;
    const showNext = () => {
        console.log('showNext', idx, images.length)
        setSearchParams({i: (idx + 1) % images.length});
    }

    const showPrev = () => {
        setSearchParams({i: (idx - 1 + images.length) % images.length});
    }

    let cropData = {};
    if (images[idx] && images[idx].cropData) {
      cropData = {
        x: images[idx].cropData.cropX,
        y: images[idx].cropData.cropY,
        w: images[idx].cropData.cropWidth,
        h: images[idx].cropData.cropHeight,
      }
    }

    let arrow = null;
    let ruler = null;
    if (images[idx] && images[idx].fieldMarks) {
      for (const fieldMark of images[idx].fieldMarks) {
        if (fieldMark.fieldMarkType === 'arrow') {
          arrow = fieldMark;
        } else if (fieldMark.fieldMarkType === 'ruler') {
          ruler = fieldMark;
        }
      }
    }

    return <ImageFill
        image={images[idx]}
        crop={cropData}
        arrow={arrow}
        ruler={ruler}
        showNext={showNext}
        showPrev={showPrev}
        close={close}
        speciesId={id} />
}
