import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import styled from 'styled-components';

import { Map } from './map';
import { SpeciesLocationChip } from './SpeciesListElement';
import { Button, ButtonLink } from "./button";
import { ImageScroller } from './Images';
import { SearchBox } from './SearchBox';
import { InteractiveMap } from './interactiveMap';
import { CollapsedCouplet } from './KeyOutOption';

export const GET_SINGLE_SPECIES = gql`
  query GetSingleSpecies($speciesID: String!) {
    species(id: $speciesID) {
      id,
      latinName,
      publication {
        author
      },
      description,
      images {
        url,
        url500,
        url1024,
        id,
        copyright,
        description,
      },
      characteristics {
        id,
        characteristicValue,
        characteristicType,
        entities {
          name,
          fnaId,
        }
      },
      imagesNeeded,
      states,
    }
  }
`;

const GET_PATHS = gql`
query GetSingleSpecies($speciesID: String!) {
  species(id: $speciesID) {
    id,
    pathsToSpecies {
      id,
      node {
        id,
        optionsFromHere {
          id,
          shortCode,
          text,
          images {
            id,
          }
        }
      }
    },
    pathsToSection {
      id,
      node {
        id,
        optionsFromHere {
          id,
          shortCode,
          text,
          images {
            id,
          }
        }
      }
    }
  }
}
`;

const SpeciesTitle = styled.span`
    font-size: 20px;
    font-weight: bold;
    font-style: italic;
    margin: 0;
`;


const SpeciesAuthor = styled.span`
    margin: 0 0 0 3px;
`;

const SpeciesHabitat = styled.span`
    margin: 0;
`;


const KeyOutPathBlock = ({ path, allOptions, keyoutidx, coupletLinkConstructor }) => {
  const [pathItems, setPathItems] = useState(path.map((item) => ({...item, selectedId: item.id})))
  let liArray = [];
  let greyed = false;
  for (let i = 0; i < pathItems.length; i++) {
    const bothOptionsOk = pathItems[i].node.optionsFromHere.every((opt) => allOptions.includes(opt.id));
    liArray.push(
      <li style={{
        listStyleType: "none",
        padding: "2px",
        border: pathItems[i].selectedId !== pathItems[i].id ? "5px solid black": "",
        backgroundColor: pathItems[i].selectedId !== pathItems[i].id ? "black" : "",
      }} key={`${pathItems[i].id}-${keyoutidx}-${i}`}>
        <CollapsedCouplet 
          nodeData={pathItems[i]} 
          selectedOptionId={pathItems[i].selectedId} 
          greyed={greyed}
          bothOptionsOk={bothOptionsOk}
          setSelectedOptionId={(id) => {
            setPathItems(pathItems.map((item, idx) => {
              if (idx === i) {
                return {...item, selectedId: id};
              }
              return item;
            }));
          }}
          coupletLinkConstructor={coupletLinkConstructor}/>
      </li>
    );
    if (pathItems[i].selectedId !== pathItems[i].id) {
      greyed = true;
    }
  }
  return (
    <div style={{
      maxWidth: "500px",
      // borderLeft: "1px solid black",
      borderBottom: "1px solid black",
      padding: "10px", 
    }}>
      {liArray}
    </div>
  )
}


export const KeyOutPaths = ({ speciesId, coupletLinkConstructor = null }) => {
  const { loading, error , data } = useQuery(GET_PATHS, { variables: {"speciesID": speciesId} });
  if (loading) return <p>Loading...</p>;
  if (error || !data.species) return <p>{"Error :("}</p>;

  const allOptionsToSpecies = data.species.pathsToSpecies.flat().map((path) => path.id);
  const allOptionsToSection = data.species.pathsToSection.flat().map((path) => path.id);
  return (
    <>
      <h3>Paths to Section</h3>
      <div style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-start",
      }}>
        {data.species.pathsToSection.map((path, idx) => <KeyOutPathBlock allOptions={allOptionsToSection} key={idx} path={path} keyoutidx={idx} coupletLinkConstructor={coupletLinkConstructor} />)}
      </div>
      <h3>Paths to Species</h3>
      <div style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-start",
      }}>
        {data.species.pathsToSpecies.map((path, idx) => <KeyOutPathBlock allOptions={allOptionsToSpecies} key={idx} path={path} keyoutidx={idx} coupletLinkConstructor={coupletLinkConstructor} />)}
      </div>
    </>
  )
}


export const Species = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_SINGLE_SPECIES, { variables: {"speciesID": id} });
  if (loading) return <p>Loading...</p>;
  if (error || !data.species) return <p>{"Error :("}</p>;
  const chars_mapped = data.species.characteristics.map((c) => {
    return {
      id: c.id,
      type: c.characteristicType,
      value: c.characteristicValue,
      entities: c.entities.map((ent) => ent.name),
    }
  });

  const habitat = chars_mapped.find((c) => c.type === "habitat");

  const charTypes = new Set(chars_mapped.map((c) => c.type));
  let charBlocks = [];
  charTypes.forEach((ct) =>
    (ct !== "habitat") && charBlocks.push(
      <div key={ct}>
        <h4 key={ct + "h4"}>{ct.charAt(0).toUpperCase() + ct.slice(1)}</h4>
        <ul key={ct + "ul1"}>
          {chars_mapped.map((item) =>
            ct === item.type && <li key={ct + item.id}>{item.value}</li>)}
        </ul>
      </div>)
  );

  return (
    <div style={{
        fontSize: "15px",
        padding: "10px",
    }}>
      <SearchBox />
      <SpeciesTitle>{data.species.latinName}</SpeciesTitle>
      <SpeciesAuthor> {data.species.publication?.author}</SpeciesAuthor>
      <div style={{margin: "4px 0px"}}><SpeciesHabitat>{habitat?.value}</SpeciesHabitat></div>
      <SpeciesLocationChip speciesData={data.species} />
      <ImageScroller images={data.species.images} speciesId={data.species.id} />

      <h3>Range</h3>
      {/* <Map range={data.species.range} /> */}
      <InteractiveMap speciesId={data.species.id} />
      {data.species.description && <>
        <h3>Discussion (FNA)</h3>
        {data.species.description?.split("\n\n").map((p, idx) => <p key={idx}>{p}</p>)}
      </>}
      <h3>Characteristics (FNA)</h3>
      {charBlocks}
      {data.species.imagesNeeded.length > 0 && <>
          <h3>Images Needed</h3>
          <ul>
            {data.species.imagesNeeded.map((n, i) => <li key={i.toString() + n}>{n}</li>)}
          </ul>
        </>
      }
      <KeyOutPaths speciesId={data.species.id} />
      <Button>
        <ButtonLink to={`/species/${data.species.id}/upload_photo`}>Add photo</ButtonLink>
      </Button>
    </div>
  );
}