import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const AdjChip_ = styled.span`
  background-color: #F2D01D;
  color: black;
  border-radius: 0.8em;
  padding: 0.1em 0.5em 0.25em 0.5em;
  font-size: 0.8em;
`;

// const AdjChip = () => (<AdjChip_>adj county</AdjChip_>);


const CurrentChip_ = styled.span`
  background-color: #95E370;
  color: black;
  border-radius: 0.8em;
  padding: 0.1em 0.5em 0.25em 0.5em;
  font-size: 0.8em;
`;


// const CurrentChip = () => (<CurrentChip_>county</CurrentChip_>);

export const SpeciesLocationChip = ({ speciesData }) => {
    let chip = null;
    if (speciesData.isCurrent) {
        chip = <CurrentChip_>{speciesData.isCurrent}</CurrentChip_>;
    } else if (speciesData.isAdj) {
        chip = <AdjChip_>{speciesData.isAdj}</AdjChip_>;
    }
    return chip;
}



const SpeciesListElementDiv = styled.div`
  min-height: 1.6em;
  line-height: 1.6em;
`

export const SpeciesListElement = ({ speciesData }) => {
  return (
    <SpeciesListElementDiv>
        <span>
            <Link to={`/species/${speciesData.id}`}>{speciesData.latinName}</Link>
            &nbsp;
            <SpeciesLocationChip speciesData={speciesData}/>
        </span>
    </SpeciesListElementDiv>
  );
}