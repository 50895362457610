import React, { useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { KeyImage } from "./KeyImage";

const ImagesWrapper = styled.div`
    display: flex;
    flex-wrap: scroll;
    flex-direction: row;
    justify-content: flex-start;
    overflow: scroll;
`;

export const ImageSquare = ({ image, setShowImageIdx }) => {
  let arrow = null;
  let ruler = null;
  if (image.fieldMarks) {
    for (const fieldMark of image.fieldMarks) {
      if (fieldMark.fieldMarkType === 'arrow') {
        arrow = fieldMark;
      } else if (fieldMark.fieldMarkType === 'ruler') {
        ruler = fieldMark;
      }
    }
  }
  let cropData = null;
  if (image.cropData) {
    cropData = {
      x: image.cropData.cropX,
      y: image.cropData.cropY,
      w: image.cropData.cropWidth,
      h: image.cropData.cropHeight,
    }
  }
  return (
    <div style={{
      width: "125px",
      height: "125px",
      margin: "20px 5px",
      flexShrink: 0,
      cursor: "pointer",
    }} onClick={setShowImageIdx}>
      <KeyImage arrow={arrow} ruler={ruler} crop={cropData} fitToSquare={true} imgSrc={image.url500 || image.url || image.image?.url} ImageMaxHeight={125} ImageMaxWidth={125} />
    </div>
  );
}


export const ImageScroller = ({ images, setShowImageIdxOverride = null, speciesId = null }) => {
  const navigate = useNavigate();
  if (setShowImageIdxOverride === null) {
    if (speciesId === null) {
      throw new Error("speciesId must be provided if setShowImageIdxOverride is null")
    }
    setShowImageIdxOverride = (idx) => {
      navigate(`/species/${speciesId}/images?i=${idx}`)
    }
  }

  return (
    <ImagesWrapper>
      {images.map((img, idx) => <ImageSquare key={img.id} image={img} setShowImageIdx={() => setShowImageIdxOverride(idx)}/>)}
    </ImagesWrapper>
  );
}
