import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import { SOURCES, addSourceToMap } from './mapSources/mapSource';
import { useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash';
 
mapboxgl.accessToken = 'pk.eyJ1IjoiY3J3Mjk5OCIsImEiOiJjbHI0MHI1cXMxZzZsMmlsZ3RrdHR2YjcxIn0.dH0uT31M2g0YDWxLRrxAeQ';


let hoveredOccurrence = null;
let activeMarker = null;
let activeCommunityMarker = null;

function Marker() {
    return (
        <div style={{backgroundColor: "white", padding: "12px", borderRadius: "4px"}}>
            <div style={{fontSize: "14px", fontWeight: "bold"}}>Marker</div>
            <div style={{fontSize: "12px"}}>Click to close</div>
        </div>
    )
}

const SidebarDiv = styled.div`
    background-color: rgb(35 55 75 / 90%);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    margin: 12px;
    border-radius: 4px;
`;

function getCircleAroundCoordinates(lat, lon, radius, numPoints) {
    const points = [];
    for (let i = 0; i < numPoints; i++) {
        const angle = (i / numPoints) * Math.PI * 2;
        const dx = radius * Math.cos(angle) / (111111*Math.cos(lat*Math.PI/180));
        const dy = radius * Math.sin(angle) / 111111;
        points.push([lon + dx, lat + dy]);
    }
    points.push(points[0]);
    return points;
}

function getGeoJSONFeatures(occurrences) {
    return occurrences.map((occurrence, i) => {
        const circle = getCircleAroundCoordinates(occurrence.lat, occurrence.lon, occurrence.coordinates_uncertainty, 40);
        return {
            type: "Feature",
            geometry: {
                type: "Polygon",
                coordinates: [circle],
            },
            properties: {
                source: occurrence.source,
                isINaturalist: occurrence.source?.includes("iNaturalist"),
                year: occurrence.year,
                locality: occurrence.locality,
                habitat: occurrence.habitat,
                uncertainty: occurrence.coordinates_uncertainty || -1,
            },
            id: i,
        }
    });
}

export const InteractiveMap = ({speciesId, onClick}) => {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const maplat = searchParams.get("maplat");
    const maplng = searchParams.get("maplng");
    const mapzoom = searchParams.get("mapzoom");
    const [lng, setLng] = useState(maplng || -70.9);
    const [lat, setLat] = useState(maplat || 42.35);
    const [zoom, setZoom] = useState(mapzoom || 9);
    const [activeSources, setActiveSources] = useState({});
    const [showOverlayOptions, setShowOverlayOptions] = useState(false);

    // add occurrences to map
    function maybeAddSourceData(map_current, occurrences_) {
        if (!map_current || !occurrences_) return;
        
        const occurrenceSourceData = {
            "type": "geojson",
            "data": {
                "type": "FeatureCollection",
                "features": getGeoJSONFeatures(occurrences_)
            },
        };
        if (!occurrences_) return;
        if (map_current.getSource("occurrenceUncertaintyBoundaries")) return;
        map_current.addSource("occurrenceUncertaintyBoundaries", occurrenceSourceData);
        map_current.addLayer({
            'id': 'occurrenceUncertaintyBoundaries',
            'type': 'fill',
            'source': 'occurrenceUncertaintyBoundaries', // reference the data source
            'layout': {},
            'paint': {
                'fill-color': '#0080ff', // blue color fill
                'fill-opacity': [
                    'case',
                    ['boolean', ['feature-state', 'hover'], false],
                    0.4,
                    0
                ]
            }
        });
        map_current.addSource("occurrenceMarkers", {
            "type": "geojson",
            "data": {
                "type": "FeatureCollection",
                "features": occurrences_.map((occurrence, i) => {
                    return {
                        "type": "Feature",
                        "geometry": {
                            "type": "Point",
                            "coordinates": [occurrence.lon, occurrence.lat]
                        },
                        "properties": {
                            "source": occurrence.source,
                            "uncertainty": occurrence.coordinates_uncertainty || -1,
                            "locality": occurrence.locality,
                            "year": occurrence.year,
                            "color": occurrence.source?.includes("iNaturalist") ? "green" : "red",
                            "habitat": occurrence.habitat,
                            "url": occurrence.url,
                            "id": occurrence.id,
                        },
                        "id": i,
                    }
                })
            }
        });
        map_current.addLayer({
            id: 'occurrenceSymbols',
            type: 'symbol',
            source: "occurrenceMarkers",
            layout: {
                'text-field': [
                    'format',
                    ['get', 'year'],
                    { 'font-scale': 1 },
                    '\n',
                    {},

                    ['get', 'habitat'],
                    {
                        'font-scale': 0.7,
                        'text-font': [
                            'literal',
                            ['DIN Offc Pro Italic', 'Arial Unicode MS Regular']
                        ]
                    }
                ],
                'text-anchor': 'top',
                'text-translate': [0, 30],
            },
        });
        map_current.addLayer({
            id: 'occurrencesWithoutUncertainty',
            type: 'circle',
            source: "occurrenceMarkers",
            // filter: ['<', ['get', 'year'], 1890],
            // filter: ['boolean', [['get', 'uncertainty'], false],
            filter: ['<=', ['get', 'uncertainty'], 0],
            paint: {
                'circle-radius': 4,
                'circle-color': [
                'interpolate', 
                ['linear'],
                ['number', ['get', 'year']],
                1600,
                'black',
                1800,
                'black',
                1900,
                '#2a4858',
                1950,
                '#005462',
                1975,
                '#005f60',
                2000,
                '#007b44',
                2016,
                '#0d840b'
                ],
                'circle-opacity': 0.6,
                'circle-stroke-color': 'black',
                'circle-stroke-width': 2,
                'circle-stroke-opacity': 1
            }
        });
        map_current.addLayer({
            id: 'occurrences',
            type: 'circle',
            source: "occurrenceMarkers",
            filter: ['>', ['get', 'uncertainty'], 0],
            paint: {
                'circle-radius': 6,
                'circle-color': [
                'interpolate', 
                ['linear'],
                ['number', ['get', 'year']],
                1600,
                'black',
                1800,
                'black',
                1900,
                '#2a4858',
                1950,
                '#005462',
                1975,
                '#005f60',
                2000,
                '#007b44',
                2016,
                '#0d840b'
                ],
                'circle-opacity': 0.6,
                'circle-stroke-color': 'white',
                'circle-stroke-width': 2,
                'circle-stroke-opacity': ['case', ['>', ['get', 'uncertainty'], 500], 1, 0],
            }
        });
        map_current.on('mousemove', 'occurrences', (e) => {
            if (e.features.length > 0) {
                // un-set
                if (hoveredOccurrence !== null) {
                    map_current.setFeatureState(
                        { source: 'occurrenceUncertaintyBoundaries', id: hoveredOccurrence },
                        { hover: false }
                    );
                }
                // set
                hoveredOccurrence = e.features[0].id;
                map_current.setFeatureState(
                    { source: 'occurrenceUncertaintyBoundaries', id: hoveredOccurrence },
                    { hover: true }
                );
            }
        });
        map_current.on('mouseleave', 'occurrences', () => {
            if (hoveredOccurrence !== null) {
                map_current.setFeatureState(
                    { source: 'occurrenceUncertaintyBoundaries', id: hoveredOccurrence },
                    { hover: false }
                );
            }
            hoveredOccurrence = null;
        });
        map_current.on('click', ['occurrences', 'occurrencesWithoutUncertainty'], (e) => {
            if (e.features.length > 0) {
                activeMarker?.remove();
                let markerElement = document.createElement('div');
                let markerTextContainer = document.createElement('div');
                markerTextContainer.className = "marker";
                let pointerElement = document.createElement('div');
                pointerElement.className = "pointer";
                // markerTextContainer.innerText = JSON.stringify(e.features[0].properties);
                Object.keys(e.features[0].properties).forEach(k => {
                    if (k === "color" || k === "id") {
                        return;
                    }
                    let value = e.features[0].properties[k];
                    if (k === "url") {
                        let link = document.createElement('a');
                        link.href = value;
                        link.target = '_blank';
                        link.innerText = "link";
                        markerTextContainer.appendChild(link);
                    } else if (value) {
                        let text = document.createElement('p');
                        text.innerText = `${k}: ${value}`;
                        markerTextContainer.appendChild(text);
                    }
                })
                let flagLink = document.createElement('a');
                flagLink.href = `/occurrence/${e.features[0].properties.id}/flag`;
                flagLink.target = '_blank';
                flagLink.innerText = "Flag";
                markerTextContainer.appendChild(flagLink);
                markerElement.appendChild(pointerElement);
                markerElement.appendChild(markerTextContainer);
                const marker = new mapboxgl.Marker({
                    element: markerElement,
                    anchor: 'bottom',
                });
                activeMarker = marker;
                markerElement.addEventListener('click', () => {
                    activeMarker.remove();
                });
                marker.setLngLat(e.features[0].geometry.coordinates).addTo(map_current);
            }
        });
    }

    const debouncedSetSearchParams = debounce((params) => {
        setSearchParams(params, {replace: true});
    }, 500);

    // fetch occurrences
    useEffect(() => {
        async function fetchOccurrences() {
            if (!speciesId) return [];
            let response = await fetch(`/occurrence/${speciesId}`)
            return response.json();
        }
        const fetchOccurrencesPromise = fetchOccurrences();
        const loadMap = new Promise((resolve, reject) => {
            if (map.current?.isStyleLoaded()) return; // initialize map only once
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/outdoors-v12',
                center: [lng, lat],
                zoom: zoom
            });
            map.current.on('move', () => {
                let lng = map.current.getCenter().lng.toFixed(4);
                let lat = map.current.getCenter().lat.toFixed(4);
                let zoom = map.current.getZoom().toFixed(2);
                setLng(lng);
                setLat(lat);
                setZoom(zoom);
                debouncedSetSearchParams({maplat: lat, maplng: lng, mapzoom: zoom});
            });
            if (onClick) {
                map.current.on('click', (e) => onClick(map.current, e));
            }
            map.current.on('load', () => {resolve(map.current)});
        });
        Promise.all([loadMap, fetchOccurrencesPromise]).then(arr => {
            maybeAddSourceData(arr[0], arr[1]);
        });
    }, [speciesId, onClick]);

    const overlaySelect = <select id="mapsrc" multiple onChange={(e) => {
        let activeSourcesTemp = {...activeSources};
        let activeOptions = Array.from(e.target.selectedOptions).map(o => o.value)
        // removals
        Object.keys(activeSourcesTemp).forEach((source_id) => {
            if (!activeOptions.includes(source_id)) {
                activeSourcesTemp[source_id].forEach(layer_id => {
                    map.current.removeLayer(layer_id);
                });
                map.current.removeSource(source_id);
                delete activeSourcesTemp[source_id];
            }
        });
        // additions
        activeOptions.forEach(value => {
            if (!activeSourcesTemp[value]) {
                let newLayers = addSourceToMap(
                    map.current, SOURCES.find(source => source.id === value));
                activeSourcesTemp[value] = newLayers;
            }
        });
        setActiveSources(activeSourcesTemp);
    }}>
        {SOURCES.map((source) => <option key={source.id} value={source.id}>{source.name}</option>)}
    </select>

    return (
        <div style={{position: "relative"}}>
            <SidebarDiv className="sidebar">
                <div>
                    Longitude: {lng} | Latitude: {lat} | Zoom: {zoom} | <a style={{color: "white", cursor: "pointer"}} onClick={() => setShowOverlayOptions(!showOverlayOptions)}>{showOverlayOptions ? "Hide Overlays" : "Overlays"}</a>
                </div>
                {showOverlayOptions && <div style={{marginTop: "10px"}}>
                    {overlaySelect}
                </div>}
            </SidebarDiv>
            <div style={{height: "800px", position: "relative"}} ref={mapContainer} className="map-container" />
        </div>
    );
}