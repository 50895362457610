import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";

import { SpeciesListElement } from './SpeciesListElement';


const GET_ALL_SPECIES_FOR_SEARCH = gql`
  query {
    allSpecies {
      id,
      latinName,
    }
  }
`;


export const SearchBox = () => {
    const [searchText, setSearchText] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    
    const { loading, error, data } = useQuery(GET_ALL_SPECIES_FOR_SEARCH);
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;
  
    const speciesList = data.allSpecies;
    const handleChange = event => {
      setSearchText(event.target.value);
      if (event.target.value.length > 2) {
        const results = speciesList.filter(species => species.latinName.toLowerCase().includes(event.target.value.toLowerCase()));
        setSearchResults(results);
      }
      else {
        setSearchResults([]);
      }
    };
  
    return (
      <div>
        <input type="text" value={searchText} onChange={handleChange} placeholder="Search" />
        <ul>
          {searchResults.map((species) => (
            <SpeciesListElement key={species.id} speciesData={species} />
          ))}
        </ul>
      </div>
    );
  }