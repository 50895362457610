import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax


export const SOURCES = [
    {
        "id": "newyork_comm",
        "url": "/map_sources/newyork_mapper__9.json",
        "name": "New York State Significant Natural Communities",
        // "line": "#FF0000",
        "fill": "#FF0000",
        "label_property": "SCIEN_NAME",
        "click_property": "COMMENTS",
        "sort_property": "ACRES",
    },
    // {
    //     "id": "newyork_rarespecies",
    //     "url": "/map_sources/newyork_mapper__11.json",
    //     "name": "New York State Endangered Plants & Animals",
    //     // "line": "#FF0000",
    //     "fill": "#FF8000",
    //     "label_property": "SCIEN_NAME",
    //     "click_property": "COMMENTS",
    // },
    {
        "id": "maine_communities",
        "url": "/map_sources/maine_PlantsNatCommunities__1.json",
        "name": "Maine Significant Natural Communities",
        // "line": "#FF0000",
        "fill": "#00FF00",
        "label_property": "SCOMNAME",
        // "click_property": "COMMENTS",
    },
    {
        "id": "maine_rareplants",
        "url": "/map_sources/maine_PlantsNatCommunities__0.json",
        "name": "Maine Rare Species",
        // "line": "#FF0000",
        "fill": "#FF8000",
        "label_property": "SCOMNAME",
        // "click_property": "COMMENTS",
    },
    {
        "id": "mass_communities",
        "url": "/map_sources/mass_communities.json",
        "name": "Massachusetts Significant Natural Communities",
        // "line": "#FF0000",
        "fill": "#FF0000",
        "label_property": "commun_nam",
        "click_property": "specific_d",
    },
    {
        "id": "vermont_species",
        "url": "/map_sources/vermont_rts__0.json",
        "name": "Vermont Rare Species",
        // "line": "#FF0000",
        "fill": "#FF8000",
        "label_property": "commun_nam",
    },
    {
        "id": "vermont_communities",
        "url": "/map_sources/vermont_snc__0.json",
        "name": "Vermont Significant Natural Communities",
        // "line": "#FF0000",
        "fill": "#FF0000",
        "label_property": "NC_NAME",
    },
    {
        "id": "connecticut_critical_habitats",
        "url": "/map_sources/connecticut_critical_habitats__0.json",
        "name": "Connecticut Critical Habitats",
        // "line": "#FF0000",
        "fill": "#FF0000",
        "label_property": ["COMMTYPE", "COMMSUBTYP"],
    },
    {
        "id": "newjersey_critical_sites",
        "url": "/map_sources/newjersey_env_habitat__93.json",
        "name": "New Jersey Critical Sites",
        // "line": "#FF0000",
        "fill": "#FF0000",
        "label_property": ["SITENAME", "BIODIVCOMM"],
    },
    {
        "id": "pennsylvania_critical_habitats",
        "url": "/map_sources/pennsylvania_cnhi__0.json",
        "name": "Pennsylvania Critical Sites",
        // "line": "#FF0000",
        "fill": "#FF0000",
        "label_property": ["SITE_NAME", "BRIEF_DESC"],
    }
];


export function addSourceToMap(map, source) {
    let addedLayers = [];
    map.addSource(source.id, {
        type: 'geojson',
        data: source.url,
    });
    if (source.line) {
        map.addLayer({
            'id': source.id + '_line',
            'type': 'line',
            'source': source.id,
            'paint': {
                'line-color': source.line,
                'line-width': 2
            }
        });
        addedLayers.push(source.id + '_line');
    }
    if (source.fill) {
        map.addLayer({
            'id': source.id + '_fill',
            'type': 'fill',
            'source': source.id,
            'paint': {
                'fill-color': source.fill,
                'fill-opacity': 0.4
            }
        });
        addedLayers.push(source.id + '_fill');
    }
    if (source.label_property) {
        let text_field = ['get', source.label_property];
        if (Array.isArray(source.label_property)) {
            text_field = ['concat', ['get', source.label_property[0]], ' - ', ['get', source.label_property[1]]]
        }
        let fill_sort_key = null;
        if (source.sort_property) {
            fill_sort_key = ['-', ['get', source.sort_property]];
        }
        map.addLayer({
            'id': source.id + '_label',
            'type': 'symbol',
            'source': source.id,
            'layout': {
                'text-field': text_field,
                'text-font': ['DIN Offc Pro Regular', 'Arial Unicode MS Regular'],
                'text-size': 10,
                'text-anchor': 'top'
            }
        });
        addedLayers.push(source.id + '_label');
    }
    if (source.click_property) {
        map.on('click', source.id, (e) => {
            var features = map.queryRenderedFeatures(e.point, { layers: [source.id + '_fill'] });
            if (!features.length) {
                return;
            }
            var feature = features[0];
            var popup = new mapboxgl.Popup()
                .setLngLat(e.lngLat)
                .setHTML(feature.properties[source.click_property])
                .addTo(map);
        });
    }
    return addedLayers;
}
