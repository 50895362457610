import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';

const ADD_INAT_IMAGE = gql`
  mutation AddInatImage($speciesID: String!, $inatObservationID: String!, $inatImageID: String!, $inatSessionID: String) {
    addInatImage(speciesId: $speciesID, inatObservationId: $inatObservationID, inatImageId: $inatImageID, inatSessionId: $inatSessionID) {
      id
    }
  }
`;


const GET_INAT_OPTIONS = gql`
  query GetInatOptions($inatTaxonId: String!) {
    inatOptions(inatTaxonId: $inatTaxonId) {
      imageUrl,
      imageId,
      inatObsId,
      inatUsername,
    }
  }
`;


const DEFAULT_TAGS = {
  "perigynium": false,
  "achene": false,
  "inflorescence": false,
  "sheath": false,
  "culm": false,
  "leaf": false,
  "spike": false,
}

const GET_TAG_OPTIONS = gql`
    query GetTagOptions {
        tagOptions {
            id,
            name,
        }
    }
`;

const TAG_IMAGE = gql`
  mutation TagImage($imageID: String!, $tagIDs: [String!]!) {
    tagImage(imageId: $imageID, tagIds: $tagIDs) {
      ok
    }
  }
`;

const TagCheckbox = ({ name, checked, onChange }) => {
  return (
    <div>
      <input type="checkbox" name={name} checked={checked} onChange={onChange} />
      <label htmlFor={name}>{name}</label><br />
    </div>
  );
}

const AddTagsToImage = ({ tagCheckboxes, setTags }) => {
  const [tagDropdownValue, setTagDropdownValue] = React.useState("Select...");
  const { loading, error, data } = useQuery(GET_TAG_OPTIONS);
  if (loading) return <p>Loading...</p>;
  if (error) return <pre>{JSON.stringify(error)}</pre>;
  const tagOptions = data.tagOptions.map(e => e.name).sort();

  const handleDropdownChange = (e) => {
    setTagDropdownValue(e.target.value);
    setTags({ ...tagCheckboxes, [e.target.value]: true });
  };

  const handleDropdownCheckbox = (e) => {
    const toValue = (tagDropdownValue === "Select...");
    if (!toValue) {
      setTagDropdownValue("Select...")
    }
  }

  return (
    <div>
      <p><b>Add image tags</b></p>
      <form id="imagetagform">
        {Object.entries(tagCheckboxes).map(
          ([name, checked]) => <TagCheckbox
            key={name}
            name={name}
            checked={checked}
            onChange={e => setTags({ ...tagCheckboxes, [name]: !checked })} />)}
        <input type="checkbox" name="other" checked={tagDropdownValue !== "Select..."} disabled={tagDropdownValue === "Select..."} onChange={handleDropdownCheckbox} />
        <label htmlFor="other">
          Other: <select value={tagDropdownValue} onChange={handleDropdownChange}>
            <option key="select..." disabled={true}>Select...</option>
            {
              tagOptions.map((to) => <option key={to} value={to}>{to}</option>)
            }
          </select>
        </label><br />
      </form>
    </div>
  );
}


const ImageSubmitter = ({ imageAddedCallback, speciesId }) => {
  const [submittingProgress, setSubmittingProgress] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [imageThumbnail, setImageThumbnail] = useState(null);
  const [tagCheckboxes, setTagCheckboxes] = React.useState(DEFAULT_TAGS);
  const [tagImage, { loading: mutationLoading, error: mutationError, data: mutationData }] = useMutation(TAG_IMAGE);

  const formSubmit = (e) => {
    var url = "/upload_image";
    var request = new XMLHttpRequest();
    request.open('POST', url, true);
    request.onload = function () { // request successful
      // we can use server response to our request now
      imageAddedCallback(e);
      // get the image id from the response
      const imageId = JSON.parse(request.responseText).imageId;
      setIsSubmitted(true);
      tagImage({
        variables: {
          "imageID": imageId,
          "tagIDs": Object.entries(tagCheckboxes).filter(([_, checked]) => checked).map(([name, _]) => name),
        }
      });
    };
    request.upload.addEventListener("progress", function (e) {
      console.log(e);
      if (e.lengthComputable) {
        var percentComplete = (e.loaded / e.total) * 100;
        setSubmittingProgress(percentComplete);
        console.log(percentComplete + "% uploaded")
      }
    }, false);

    request.onerror = function () {
      // request failed
      console.log(request.responseText)
    };

    request.send(new FormData(e.target)); // create FormData from form that triggered event\
    e.preventDefault();
  }

  const handleImageFileChange = (e) => {
    if (e.target.files[0]) {
      imageAddedCallback();
      setImageThumbnail(URL.createObjectURL(e.target.files[0]));
    }
  }

  return (
    <div style={{
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      flexDirection: "column",
      padding: "15px",
      border: "1px solid black",
      margin: "7px",
    }}>
      <div style={{
        marginBottom: "15px",
      }}>
        <img src={imageThumbnail} style={{
          maxWidth: "300px",
          maxHeight: "300px",
        }} />
      </div>
      <form method="POST" encType="multipart/form-data" onSubmit={(e) => formSubmit(e)}>
        <input type="hidden" name="species_id" value={speciesId} />
        <input id="image-upload" name="image" type="file" accept="image/png, image/jpeg, image/tiff" onChange={handleImageFileChange} />
        <div style={{
          height: "20px",
        }}></div>
        <textarea style={{
          fontFamily: "sans-serif",
        }} rows="5" cols="33" name="description" placeholder="Description" />
        <div style={{
          height: "20px",
        }}></div>
        { // progress bar
        submittingProgress && <div style={{
          width: "100%",
          height: "20px",
          border: "1px solid black",
          borderRadius: "5px",
          overflow: "hidden",
        }}>
          <div style={{
            width: `${submittingProgress}%`,
            height: "100%",
            backgroundColor: "green",
          }}></div>
        </div>}
        {isSubmitted ? <p>Success!</p> : <input type="submit" value="Upload" />}
      </form>
      {
        (() => {
          if (mutationLoading) return <p>Loading...</p>;
          if (mutationError) return <pre>{JSON.stringify(mutationError)}</pre>;
          if (mutationData) return <p>Tags added!</p>;
          return <AddTagsToImage tagCheckboxes={tagCheckboxes} setTags={setTagCheckboxes} />
        })()
      }
      
    </div>
  )
}


export const UploadPhoto = () => {
  const { id } = useParams();
  const [numberImageForms, setImageForms] = useState(1);
  const [inatSpeciesId, setInatSpeciesId] = useState(null);
  const [addInatImage, { loading: mutationLoading, error: mutationError, data: mutationData }] = useMutation(ADD_INAT_IMAGE);
  const [getINatOptions, { loading: optionsLoading, error: optionsError, data: optionsData }] = useLazyQuery(GET_INAT_OPTIONS, {
    variables: { inatTaxonId: inatSpeciesId },
    skip: !inatSpeciesId,
  });

  const handleSubmitINat = (e) => {
    e.preventDefault();
    const form = document.getElementById("inat-form");
    const formData = new FormData(form);
    const variables = {
      speciesID: id,
      inatObservationID: formData.get("inat_observation_id"),
      inatImageID: formData.get("inat_image_id"),
      inatSessionID: formData.get("inat_session_id"),
    };
    addInatImage({ variables: variables });
  }

  const handleSubmitInatFromOptions = (e, idx) => {
    e.preventDefault();
    const data = optionsData.inatOptions[idx]
    const variables = {
      speciesID: id,
      inatObservationID: data.inatObsId,
      inatImageID: data.imageId,
      inatSessionID: null,
    };
    addInatImage({ variables: variables });
  }



  let imageForms = [];
  for (let i = 0; i < numberImageForms; i++) {
    imageForms.push(
      <ImageSubmitter speciesId={id} key={i} imageAddedCallback={(e) => {
        if (i === numberImageForms - 1) {
          setImageForms(numberImageForms + 1);
        }
      }} />
    );
  }

  return (
    <div>
      <div style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        flexDirection: "row",
        margin: "50px",
      }}>
        {imageForms}
      </div>
      <h2>Add photo from iNaturalist</h2>
      <form id="inat-form">
        <input type="text" name="inat_observation_id" placeholder="iNat obs ID" /><br />
        <input type="text" name="inat_image_id" placeholder="iNat image ID" /><br />
        <input type="text" name="inat_session_id" placeholder="iNat session ID" /><br />
        <input type="submit" value="Upload" onClick={handleSubmitINat} />
      </form>
      <form id="inat-form" style={{ marginTop: "50px" }}>
        <input type="text" name="inat_species_id" placeholder="iNat species ID" onChange={(e) => setInatSpeciesId(e.target.value)} /><br />
        <input type="submit" value="Get options" onClick={(e) => {
          e.preventDefault();
          getINatOptions();
        }} />
      </form>

      {(optionsLoading || mutationLoading) && <p>Loading...</p>}
      {(optionsError || mutationError) && <p>Error :( Please try again</p>}
      {mutationData && <p>Success!</p>}
      <div style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        flexDirection: "row",
      }}>
        {optionsData ? optionsData.inatOptions.map((opt, idx) => {
          return (
            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: "10px",
            }} key={idx}>
              <a target="_blank" rel="noreferrer" href={`https://www.inaturalist.org/observations/${opt.inatObsId}`}>
                <img src={opt.imageUrl} style={{
                  objectFit: "cover",
                  width: "250px",
                  height: "250px",
                }} />
              </a>
              <span>{opt.inatUsername}</span>
              <form id="inat-form">
                <input type="submit" value="Upload" onClick={(e) => handleSubmitInatFromOptions(e, idx)} />
              </form>
            </div>
          );
        }) : null}
      </div>
    </div>
  )
}