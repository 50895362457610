import React from 'react';
import { useQuery, gql } from '@apollo/client';
import styled from 'styled-components';

import { SpeciesListElement } from './SpeciesListElement';


// isCurrent,
// isAdj,
// isNewEngland,
// imagesNeeded,
// range {
//     county,
//     state
// }

const ALL_SPECIES = gql`
    query GetAllSpecies {
        allSpecies {
            id,
            latinName,

            publication {
                author
            },
            images {
                url,
                id,
                description,
                entities {
                    name,
                    id,
                }
            },
            keyNode {
                id,
            },
            characteristics {
                id,
                characteristicValue,
                characteristicType,
                entities {
                    name,
                    fnaId,
                }
            },
        }
    }
`;

const Table = styled.table`
    border-collapse: collapse;
`;

const SpeciesTableHeader = styled.th`
    border: 1px solid black;
    padding: 0.5em;
`;

const SpeciesTableData = styled.td`
    border: 1px solid black;
    padding: 0.5em;
`;

export const SpeciesTable = () => {
    const [newEnglandOnly, setNewEnglandOnly] = React.useState(false);
    const [missingImagesOnly, setMissingImagesOnly] = React.useState(false);
    const [missingOriginalImagesOnly, setMissingOriginalImagesOnly] = React.useState(false);
    const { loading, error, data } = useQuery(ALL_SPECIES);
    if (loading) return <p>Loading...</p>;
    if (error) console.log(error);
    if (error) return <p>Error :(</p>;
    return (
        <div>
            <div style={{display: "flex", justifyContent: "flex-start", flexDirection: "row"}}>
                <input name="newenglandonlycheckbox" type="checkbox" value={newEnglandOnly} onClick={(e) => {setNewEnglandOnly(!newEnglandOnly)}} />    
                <label htmlFor="newenglandonlycheckbox">New England Only</label>
                <input name="missingimagesonlycheckbox" type="checkbox" value={missingImagesOnly} onClick={(e) => {setMissingImagesOnly(!missingImagesOnly)}} />    
                <label htmlFor="missingimagesonlycheckbox">Missing Images Only</label>
                <input name="missingoriginalimagesonlycheckbox" type="checkbox" value={missingOriginalImagesOnly} onClick={(e) => {setMissingOriginalImagesOnly(!missingOriginalImagesOnly)}} />
                <label htmlFor="missingoriginalimagesonlycheckbox">Missing Original Images Only</label>
            </div>
            <Table>
                <thead>
                    <tr>
                        <SpeciesTableHeader>Latin Name</SpeciesTableHeader>
                        <SpeciesTableHeader>New England</SpeciesTableHeader>
                        <SpeciesTableHeader>Original Images</SpeciesTableHeader>
                        <SpeciesTableHeader>iNat Images</SpeciesTableHeader>
                        <SpeciesTableHeader>Image: leaf</SpeciesTableHeader>
                        <SpeciesTableHeader>Image: perigynium</SpeciesTableHeader>
                        <SpeciesTableHeader>Image: spike</SpeciesTableHeader>
                        <SpeciesTableHeader>Image: inflorescence</SpeciesTableHeader>
                        <SpeciesTableHeader>Image: culm</SpeciesTableHeader>
                        <SpeciesTableHeader>Image: achene</SpeciesTableHeader>
                        <SpeciesTableHeader>Range</SpeciesTableHeader>
                        <SpeciesTableHeader>Characteristics</SpeciesTableHeader>
                        <SpeciesTableHeader>Habitat</SpeciesTableHeader>
                        <SpeciesTableHeader>Publication</SpeciesTableHeader>
                    </tr>
                </thead>
                <tbody>
                    {data.allSpecies.map((speciesData) => {
                        if (newEnglandOnly && !speciesData.isNewEngland) return null;
                        if (missingImagesOnly && speciesData.images?.length > 0) return null;
                        if (missingOriginalImagesOnly && speciesData.images.some(
                            image => image.url.includes("colewinstanley.com"))) return null;

                        return (<tr key={speciesData.id}>
                                <SpeciesTableData>
                                    <SpeciesListElement speciesData={speciesData} />
                                </SpeciesTableData>
                                <SpeciesTableData style={{backgroundColor: speciesData.isNewEngland ? "lightgreen" : "lightred"}}>
                                </SpeciesTableData>
                                <SpeciesTableData style={{backgroundColor: speciesData.images.some(
                                    image => image.url.includes("colewinstanley.com")) ? "lightgreen" : "lightred"}}>
                                </SpeciesTableData>
                                <SpeciesTableData style={{backgroundColor: speciesData.images.some(
                                    image => image.url.includes("inaturalist")) ? "lightgreen" : "lightred"}}>
                                </SpeciesTableData>
                                <SpeciesTableData style={{backgroundColor: speciesData.images.some(
                                    image => image.entities.some(e => e.name === "leaf")) ? "lightgreen" : "lightred"}}>
                                </SpeciesTableData>
                                <SpeciesTableData style={{backgroundColor: speciesData.images.some(
                                    image => image.entities.some(e => e.name === "perigynium")) ? "lightgreen" : "lightred"}}>
                                </SpeciesTableData>
                                <SpeciesTableData style={{backgroundColor: speciesData.images.some(
                                    image => image.entities.some(e => e.name === "spike")) ? "lightgreen" : "lightred"}}>
                                </SpeciesTableData>
                                <SpeciesTableData style={{backgroundColor: speciesData.images.some(
                                    image => image.entities.some(e => e.name === "inflorescence")) ? "lightgreen" : "lightred"}}>
                                </SpeciesTableData>
                                <SpeciesTableData style={{backgroundColor: speciesData.images.some(
                                    image => image.entities.some(e => e.name === "culm")) ? "lightgreen" : "lightred"}}>
                                </SpeciesTableData>
                                <SpeciesTableData style={{backgroundColor: speciesData.images.some(
                                    image => image.entities.some(e => e.name === "achene")) ? "lightgreen" : "lightred"}}>
                                </SpeciesTableData>
                                <SpeciesTableData style={{backgroundColor: speciesData.range?.length > 0 ? "lightgreen" : "lightred"}}>
                                </SpeciesTableData>
                                <SpeciesTableData style={{backgroundColor: speciesData.characteristics ? "lightgreen" : "lightred"}}>
                                </SpeciesTableData>
                                <SpeciesTableData style={{backgroundColor: speciesData.characteristics.some(c => c.characteristicType === "habitat") ? "lightgreen" : "lightred"}}>
                                </SpeciesTableData>
                                <SpeciesTableData style={{backgroundColor: speciesData.publication?.author ? "lightgreen" : "lightred"}}>
                                    {speciesData.publication?.author}
                                </SpeciesTableData>
                            </tr>
                        );
                        })}
                </tbody>
            </Table>
        </div>
    );
}
