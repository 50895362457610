import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { gql, useQuery, useMutation } from "@apollo/client";

import { ImageCropper } from "./ImageCropper";
import { KeyImage } from "./KeyImage";


/*
image_id = graphene.String(required=True)
key_out_option_id = graphene.String(required=True)
description = graphene.String(required=True)
field_marks = graphene.List(FieldMarkData, required=False)
crop_x = graphene.Float(required=False)
crop_y = graphene.Float(required=False)
crop_width = graphene.Float(required=False)
crop_height = graphene.Float(required=False)
*/

const ADD_IMAGE_TO_KEY = gql`
  mutation AddImageToKey($imageID: String!,
                         $keyOutOptionID: String!,
                         $description: String!,
                         $fieldMarks: [FieldMarkData],
                         $cropX: Float,
                         $cropY: Float,
                         $cropWidth: Float,
                         $cropHeight: Float) {
    addKeyOutImage(imageId: $imageID,
                   keyOutOptionId: $keyOutOptionID,
                   description: $description,
                   fieldMarks: $fieldMarks,
                   cropX: $cropX,
                   cropY: $cropY,
                   cropWidth: $cropWidth,
                   cropHeight: $cropHeight) {
      id
    }
  }
`;

const GET_IMAGE = gql`
    query GetImage($imageID: String!) {
        image(id: $imageID) {
            id,
            url,
        }
    }
`;

export const AddFieldMark = () => {
    const { photo_id, option_id } = useParams();
    const [cropArea, setCropArea] = React.useState(null);
    const [arrow, setArrow] = React.useState(null);
    const [ruler, setRuler] = React.useState(null);
    const [selectedTool, setSelectedTool] = useState('crop');
    const [description, setDescription] = useState('');

    const handleRadioChange = (e) => {
        setSelectedTool(e.target.value);
    }
    const [addImageToKeyOutOption, { 
        loading: mutationLoading, 
        error: mutationError, 
        data: mutationData }] = useMutation(ADD_IMAGE_TO_KEY);

    const { loading, error, data } = useQuery(GET_IMAGE, {
        variables: { imageID: photo_id },
    });
    const image = data?.image;

    const setArrowOrRuler = (params) => {
        if (selectedTool === 'arrow') {
            setArrow(params);
        }
        else if (selectedTool === 'ruler') {
            setRuler(params);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let fieldMarks = []
        if (arrow) {
            fieldMarks.push({
                x1: arrow.x1,
                y1: arrow.y1,
                x2: arrow.x2,
                y2: arrow.y2,
                fieldMarkType: 'arrow'
            })
        }
        if (ruler) {
            fieldMarks.push({
                x1: ruler.x1,
                y1: ruler.y1,
                x2: ruler.x2,
                y2: ruler.y2,
                fieldMarkType: 'ruler'
            })
        }


        addImageToKeyOutOption(
            { 
                variables: { 
                    imageID: photo_id,
                    keyOutOptionID: option_id,
                    description: description,
                    cropX: cropArea?.x,
                    cropY: cropArea?.y,
                    cropWidth: cropArea?.w,
                    cropHeight: cropArea?.h,
                    fieldMarks: fieldMarks
                }
            }
        )
    }
    
    if (mutationLoading || loading) return <p>Loading...</p>;
    if (mutationError || error) return <pre>{JSON.stringify(mutationError)}</pre>;
    if (mutationData) return <p>Key image added successfully</p>;
    return (
        <div>
            <h1>Add image to key</h1>
            <img src={data.image.url} width="200px" alt={image.id} />
            <div>
                <input type="textarea" name="description" placeholder="Description" 
                    value={description} onChange={(e) => setDescription(e.target.value)} />
                <label>
                    <input
                    type="radio" 
                    value="crop" 
                    checked={selectedTool === 'crop'}
                    onChange={handleRadioChange} 
                    />
                    Crop
                </label>
                <label>
                    <input 
                    type="radio" 
                    value="arrow" 
                    checked={selectedTool === 'arrow'} 
                    onChange={handleRadioChange}
                    />
                    Arrow
                </label>
                <label>
                    <input 
                    type="radio" 
                    value="ruler" 
                    checked={selectedTool === 'ruler'} 
                    onChange={handleRadioChange}
                    />
                    Ruler
                </label>
            </div>
            <ImageCropper drawArrow={selectedTool === 'arrow' || selectedTool === 'ruler'} setArrow={setArrowOrRuler} imgSrc={data.image.url} setCropArea={setCropArea}/>
            <KeyImage arrow={arrow} ruler={ruler} crop={cropArea} imgSrc={data.image.url} ImageMaxWidth={500} ImageMaxHeight={500} />
            <input type="submit" value="Submit" onClick={handleSubmit} />
        </div>
    );
}