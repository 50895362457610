import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { gql, useQuery, useMutation } from "@apollo/client";


const ADD_OCCURRENCE_FLAG = gql`
    mutation AddOccurrenceFlag($occurrenceID: String!,
                               $excludeFromData: Boolean!,
                               $comment: String) {
            addOccurrenceFlag(occurrenceId: $occurrenceID,
            excludeFromData: $excludeFromData,
            comment: $comment) {
                id
          }
     }
`;

const GET_OCCURRENCE = gql`
    query Occurrence($occurrenceID: String!) {
        occurrence(id: $occurrenceID) {
            id,
            countyId,
            lat,
            lon,
            coordinatesUncertainty,
            source,
            habitat,
            year,
            locality,
            url,
            flags {
                id,
                excludeFromData,
                comment,
            }
        }
    }
`;

export const AddOccurrenceFlag = () => {
    const { occurrence_id } = useParams();
    const [excludeFromData, setExcludeFromData] = useState(false);
    const [comment, setComment] = useState('');

    const [addOccurrenceFlag, { data }] = useMutation(ADD_OCCURRENCE_FLAG);

    const handleExcludeFromDataChange = (e) => {
        setExcludeFromData(e.target.checked);
    }

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        addOccurrenceFlag({ variables: { occurrenceID: occurrence_id,
                                         excludeFromData: excludeFromData,
                                         comment: comment } });
    }

    const { loading, error, data: occurrenceData } = useQuery(GET_OCCURRENCE, { variables: { occurrenceID: occurrence_id } });
    if (loading) return <p>Loading...</p>;
    if (error) console.log(error);
    if (error) return <p>Error :(</p>;

    return (
        <div>
            <h1>Add Occurrence Flag</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    Exclude from data:
                    <input type="checkbox" checked={excludeFromData} onChange={handleExcludeFromDataChange} />
                </label>
                <label>
                    Comment:
                    <input type="text" value={comment} onChange={handleCommentChange} />
                </label>
                <input type="submit" value="Submit" />
            </form>
            <h2>Occurrence</h2>
            <p>{occurrenceData.occurrence.locality}</p>
            <p>{occurrenceData.occurrence.countyId}</p>
            <p>{occurrenceData.occurrence.lat}</p>
            <p>{occurrenceData.occurrence.lon}</p>
            <p>{occurrenceData.occurrence.coordinatesUncertainty}</p>
            <p>{occurrenceData.occurrence.source}</p>
            <p>{occurrenceData.occurrence.habitat}</p>
            <p>{occurrenceData.occurrence.year}</p>
            <p>{occurrenceData.occurrence.url}</p>
            <h2>Flags</h2>
            <ul>
                {occurrenceData.occurrence.flags.map((flag) => (
                    <li key={flag.id}>{flag.excludeFromData ? "Exclude from data" : "Include in data"}: {flag.comment}</li>
                ))}
            </ul>
        </div>
    );
}