import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { ImageScroller } from './Images';
import { Button, ButtonLink } from './button';
import { KeyImage } from './KeyImage';


const OPTION_HEIGHT = 250;

const KeyOutImageDescription = styled.div`
    opacity: 0.7;
    background-color: black;
    color: white;
    padding: 1px;
    font-size: 13px;
`;


export const CollapsedCouplet = ({ nodeData, selectedOptionId, bothOptionsOk, setSelectedOptionId, greyed, coupletLinkConstructor = null }) => {
  const navigate = useNavigate();
  return (
    <div style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      // padding: "2px",
      // backgroundColor: greyed ? "#f0f0f0" : "green",
      // borderRadius: "2px",
      fontSize: "12px",
    }}>
      <div>
        {/* {nodeData.shortCode}. {nodeData.text} */}
      </div>
      {nodeData.node.optionsFromHere.map((option, idx) => {
        let background = null;
        if (selectedOptionId === option.id) {
          background = "green";
        } else if (bothOptionsOk) {
          background = "repeating-linear-gradient(-0.12turn, orange, orange 4px, #f5d9a5 4px, #f5d9a5 8px)"
        } else {
          background = "orange";
        }
        const getLink = coupletLinkConstructor ? coupletLinkConstructor : (nodeId) => {
          return "/key/" + nodeId;
        }
        return <Button key={option.id} onClick={() => {
          if (selectedOptionId !== option.id) {
            setSelectedOptionId(option.id);
          } else {
            navigate(getLink(nodeData.node.id));
          }
        }} style={{
          background: background,
          color: selectedOptionId === option.id ? "white" : "black",
          flexGrow: selectedOptionId === option.id ? 1 : 0,
          textAlign: "left",
          padding: "5px",
          borderTopLeftRadius: idx === 0 ? "5px" : "0px",
          borderBottomLeftRadius: idx === 0 ? "5px" : "0px",
          borderTopRightRadius: idx === 1 ? "5px" : "0px",
          borderBottomRightRadius: idx === 1 ? "5px" : "0px",
          minWidth: "40px",
          margin: "0px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          opacity: greyed ? 0.5 : 1,
          animationDuration: "0.5s",
          animationName: "fadein",
        }}>
          <div>
            <b>{option.shortCode}.&nbsp;</b>
            {(selectedOptionId === option.id) && option.text}
          </div>
          <div style={{marginLeft: "5px"}}>
          {(selectedOptionId === option.id) && (option.images?.length === 0) && <span className="material-symbols-outlined" 
              style={{ fontSize: "15px" }}>
            no_photography
          </span>}
          </div>
        </Button>
      })}
    </div>
  );
}


export const KeyOutOption = ({ data, setSpeciesModalNodeID }) => {
  const [showImageIdx, setShowImageIdx] = React.useState(null);
  const region = ["NY", "MA", "CT", "RI", "VT", "NH", "ME"];
  if (!data) return null;

  const buttonLink = data.toNode.taxon?.taxonType === "species" ? `/species/${data.toNode.taxon.id}` : `/key/${data.toNode.id}`;
  const buttonText = data.toNode.taxon?.latinName || "Select this option";

  const outOfRegion = data.toNode.states && !data.toNode.states.some(state => region.includes(state.id));

  let cropData = {};
  if (showImageIdx !== null && data.images[showImageIdx] && data.images[showImageIdx].cropData) {
    cropData = {
      x: data.images[showImageIdx].cropData.cropX,
      y: data.images[showImageIdx].cropData.cropY,
      w: data.images[showImageIdx].cropData.cropWidth,
      h: data.images[showImageIdx].cropData.cropHeight,
    }
  }

  return (
    <div style={{
      position: "relative",
      minHeight: `${OPTION_HEIGHT}px`,
    }} className="key-out-option">
      {showImageIdx !== null && 
        <div style={{
          position: "absolute",
          top: "0px",
          left: "0px",
          width: "100%",
          height: "100%",
          background: "#000000cc",
          display: "flex",
          justifyContent: "center",
          flexDirection: "row",
        }}
        onClick={(e) => {
          e.stopPropagation();
          setShowImageIdx(null)
        }}>
          <KeyImage 
            imgSrc={data.images[showImageIdx].image.url}
            arrow={data.images[showImageIdx].fieldMarks.find(fm => fm.fieldMarkType === "arrow")}
            ruler={data.images[showImageIdx].fieldMarks.find(fm => fm.fieldMarkType === "ruler")}
            crop={cropData}
            ImageMaxHeight={OPTION_HEIGHT}
            ImageMaxWidth={window.screen.width}
            dpr={10}
          />
          <div style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
          }}>
            <KeyOutImageDescription>{data.images[showImageIdx].description}</KeyOutImageDescription>
          </div>
        </div>
      }
      <div style={{
        fontWeight: "bold",
        padding: "5px",
      }}>{data.shortCode}.</div>
      <div style={{
        // padding: "5px",
        marginLeft: "20px",
      }}>{data.text}</div>
      <div style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "0px 5px",
      }}>
        <ImageScroller images={data.images}
          setShowImageIdxOverride={setShowImageIdx} />
        <div style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
        }}>
          
          {outOfRegion ? <>
            <Button style={{backgroundColor: "lightgray"}}><ButtonLink disabled={true} to={buttonLink}>{buttonText}</ButtonLink></Button>
            <p style={{color: "red", textAlign: "center"}}>Out of region<br /><a style={{fontSize: "10px"}} href={buttonLink}>Select anyway</a></p>
          </> : <Button><ButtonLink to={buttonLink}>{buttonText}</ButtonLink></Button>}
          {data.toNode.taxon?.taxonType !== "species" &&
            <Button onClick={() => setSpeciesModalNodeID(data.toNode.id)}>
              Species List
            </Button>
          }
        </div>
      </div>
      
      
    </div>
  );
}