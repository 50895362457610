import React from 'react';
import { useParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import { useReadLocalStorage } from 'usehooks-ts'


const KeyOutPathWrapper = styled.div`
  border: 1px solid lightgrey;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;


const KeyOutSeparator = () => {
  return (
    <div style={{
        position: "relative",
        marginLeft: "0.2em",
        marginRight: "0.5em",
      }}>
      <div style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "1.4px",
        height: "100%",
        backgroundColor: "lightgrey",
      }}></div>
      <div style={{
        position: "absolute",
        left: "1.4px",
        top: 0,
        width: "0px",
        height: "0",
        borderLeft: "0.4em solid lightgrey",
        borderTop: "0.6em solid transparent",
        borderBottom: "0.6em solid transparent",
      }}></div>
      <div style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "0px",
        height: "0",
        borderLeft: "0.4em solid white",
        borderTop: "0.6em solid transparent",
        borderBottom: "0.6em solid transparent",
      }}></div>
    </div>
  );
}


const KeyOutItem = ({ children }) => {
  return (
    <div>{children}</div>
  );
}

export const KeyOutPath = () => {
  const { node_id } = useParams();
  const path = useReadLocalStorage("path", []);
  if (!path || path.length === 0) return null;
  const pathShortCodes = path.map((node) => <KeyOutItem key={node.id}><Link to={`/key/${node.toNode.id}`}>{node.shortCode}</Link></KeyOutItem>);
  return (
    <KeyOutPathWrapper>
      {pathShortCodes.reduce((prev, curr, idx) => [prev, <KeyOutSeparator key={idx}/>, curr])}
    </KeyOutPathWrapper>
  );
}