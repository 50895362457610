import React from 'react';
import styled from 'styled-components';

const SpeciesTitle = styled.span`
    font-size: 20px;
    font-weight: bold;
    font-style: italic;
    margin: 0;
`;


const SpeciesAuthor = styled.span`
    margin: 0 0 0 3px;
`;


export const Taxon = ({ taxon }) => {
    if (!taxon) {
        return null;
    }
    return (
        <div>
            <SpeciesTitle>{taxon.latinName}</SpeciesTitle>
            <SpeciesAuthor> {taxon.publication?.author}</SpeciesAuthor>
        </div>
    );
}