import React, { useRef, useEffect, useState } from 'react';

export function ImageCropper({ imgSrc, setCropArea, setArrow, drawArrow = false }) {
    const imageCanvasRef = useRef(null);
    const cropCanvasRef = useRef(null);
    const [cropStart, setCropStart] = useState(null);
    const [cropEnd, setCropEnd] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [scale, setScale] = useState(1);
  
    const drawImage = () => {
      const canvas = imageCanvasRef.current;
      const cropCanvas = cropCanvasRef.current;
      const context = canvas.getContext('2d');
      const cropContext = cropCanvas.getContext('2d');
  
      let img = new Image();
      img.onload = function() {
        const dpr = window.devicePixelRatio || 1;
        let imageScale = Math.min(750 / img.width, 750 / img.height);
        setScale(imageScale * dpr);
        let width = img.width * imageScale;
        let height = img.height * imageScale;
  
        canvas.width = width * dpr;
        canvas.height = height * dpr;
        canvas.style.width = `${width}px`;
        canvas.style.height = `${height}px`;
        context.scale(dpr, dpr);

        cropCanvas.width = width * dpr;
        cropCanvas.height = height * dpr;
        cropCanvas.style.width = `${width}px`;
        cropCanvas.style.height = `${height}px`;
        cropContext.scale(dpr, dpr);
  
        context.drawImage(img, 0, 0, width, height);
      }
      img.src = imgSrc;
    }
  
    const handleMouseDown = e => {
      const rect = imageCanvasRef.current.getBoundingClientRect();
      setCropStart({ x: e.clientX - rect.left, y: e.clientY - rect.top });
      setIsDragging(true);
    }
  
    const handleMouseUp = e => {
      setIsDragging(false);
      drawShape();
    }
  
    const handleMouseMove = e => {
      if (!isDragging) return;
  
      const rect = imageCanvasRef.current.getBoundingClientRect();
      const currentCropEnd = { x: e.clientX - rect.left, y: e.clientY - rect.top };
      setCropEnd(currentCropEnd);
      drawShape(currentCropEnd);
    }
  
    // const drawCropBox = (currentCropEnd = cropEnd) => {
    //   const canvas = cropCanvasRef.current;
    //   const context = canvas.getContext('2d');
    //   context.clearRect(0, 0, canvas.width, canvas.height);
  
    //   if (!cropStart || !currentCropEnd) return;
  
    //   context.beginPath();
    //   context.rect(cropStart.x, cropStart.y, currentCropEnd.x - cropStart.x, currentCropEnd.y - cropStart.y);
    //   context.strokeStyle = 'red';
    //   context.lineWidth = 2;
    //   context.stroke();
    // }

    const drawShape = (currentCropEnd = cropEnd) => {
        const canvas = cropCanvasRef.current;
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, canvas.width, canvas.height);
    
        if (!cropStart || !currentCropEnd) return;
    
        context.beginPath();
    
        if (drawArrow) {
          const dx = currentCropEnd.x - cropStart.x;
          const dy = currentCropEnd.y - cropStart.y;
          const angle = Math.atan2(dy, dx);
          const headlen = 10;
          const headAngle = Math.PI / 6;
    
          context.moveTo(cropStart.x, cropStart.y);
          context.lineTo(currentCropEnd.x, currentCropEnd.y);
          context.lineTo(currentCropEnd.x - headlen * Math.cos(angle - headAngle), currentCropEnd.y - headlen * Math.sin(angle - headAngle));
          context.moveTo(currentCropEnd.x, currentCropEnd.y);
          context.lineTo(currentCropEnd.x - headlen * Math.cos(angle + headAngle), currentCropEnd.y - headlen * Math.sin(angle + headAngle));
        } else {
          context.rect(cropStart.x, cropStart.y, currentCropEnd.x - cropStart.x, currentCropEnd.y - cropStart.y);
        }
    
        context.strokeStyle = 'red';
        context.lineWidth = 2;
        context.stroke();
    }
  
    const handleSubmit = () => {
      if (!cropStart || !cropEnd) return;
      const dpr = window.devicePixelRatio || 1;
      const s = dpr / scale;

      if (drawArrow) {
        console.log(cropStart, cropEnd)
        setArrow({x1: s*cropStart.x, x2: s*cropEnd.x, y1: s*cropStart.y, y2: s*cropEnd.y});
      } else {
        const minX = Math.min(cropStart.x, cropEnd.x);
        const minY = Math.min(cropStart.y, cropEnd.y);
        const maxX = Math.max(cropStart.x, cropEnd.x);
        const maxY = Math.max(cropStart.y, cropEnd.y);

    
        const x = s*minX;
        const y = s*minY
        const width = s * (maxX - minX);
        const height = s * (maxY - minY);
    
        setCropArea({x, y, w: width, h: height});
      }
    }
  
    useEffect(drawImage, [imgSrc]);
  
    return (
      <div style={{ position: 'relative', height: "750px" }}>
        <canvas
          ref={imageCanvasRef}
          style={{ position: 'absolute' }}
        />
        <canvas ref={cropCanvasRef} onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove} style={{ position: 'absolute', zIndex: 1 }} />
        <button onClick={handleSubmit} style={{
            position: 'fixed',
            right: '10px',
        }}>Add</button>
      </div>
    );
}