import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Button = styled.div`
    background-color: #95E370;
    // border: 1px solid #ccc;
    padding: 5px;
    margin: 5px;
    cursor: pointer;
    text-align: center;
    &:hover {
        opacity: 0.8;
    }
}
`;

export const ButtonLink = styled(Link)`
    color: black;
    text-decoration: none;
`;