import React from 'react';
import { useQuery, gql } from '@apollo/client';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { SpeciesListElement } from './SpeciesListElement';

const ALL_NODES = gql`
    query GetAllSpecies {
        allKeyOutOptions {
            id,
            text,
            shortCode,
            images {
                fieldMarks {
                    id,
                },
            },
            toNode {
                id,
                taxon {
                    latinName,
                    taxonType,
                    id,
                }
            },
            node {
                id,
                shortCode,
                marks {
                    id,
                }
                optionsFromHere {
                    id,
                }
            }
        }
    }
`;

const Table = styled.table`
    border-collapse: collapse;
`;

const SpeciesTableHeader = styled.th`
    border: 1px solid black;
    padding: 0.5em;
`;

const SpeciesTableData = styled.td`
    border: 1px solid black;
    padding: 0.5em;
`;

export const NodesTable = () => {
    const { loading, error, data } = useQuery(ALL_NODES);
    if (loading) return <p>Loading...</p>;
    if (error) console.log(error);
    if (error) return <p>Error :(</p>;
    return (
        <Table>
            <thead>
                <tr>
                    <SpeciesTableHeader>ID</SpeciesTableHeader>
                    <SpeciesTableHeader>Short Code</SpeciesTableHeader>
                    <SpeciesTableHeader>From Node</SpeciesTableHeader>
                    <SpeciesTableHeader>To Node</SpeciesTableHeader>
                    <SpeciesTableHeader>Has Images</SpeciesTableHeader>
                    <SpeciesTableHeader>Has Field Marks</SpeciesTableHeader>
                    <SpeciesTableHeader>Has Text</SpeciesTableHeader>
                    <SpeciesTableHeader>Marks</SpeciesTableHeader>
                    <SpeciesTableHeader>Taxon</SpeciesTableHeader>
                </tr>
            </thead>
            <tbody>
                {data.allKeyOutOptions.map((keyOutOptionData) => {
                    const nodeData = keyOutOptionData.node;
                    const toNodeData = keyOutOptionData.toNode;
                    let imagesColor = "lightred";
                    let fieldMarksColor = "lightred";
                    console.log(nodeData.id, nodeData.optionsFromHere.length)
                    if (keyOutOptionData.images.length > 0) {
                        imagesColor = "lightgreen";
                    } else if (nodeData.optionsFromHere.length < 2) {
                        imagesColor = "lightgray";
                    }
                    if (keyOutOptionData.images.some(image => image.fieldMarks.length > 0)) {
                        fieldMarksColor = "lightgreen";
                    } else if (nodeData.optionsFromHere.length < 2) {
                        fieldMarksColor = "lightgray";
                    }
                    return (
                    <tr key={keyOutOptionData.id}>
                        <SpeciesTableData>
                            {keyOutOptionData.id}
                        </SpeciesTableData>
                        <SpeciesTableData>
                            {keyOutOptionData.shortCode}
                        </SpeciesTableData>
                        <SpeciesTableData>
                        <Link to={`/key/${nodeData.id}`}>{nodeData.id}</Link>
                        </SpeciesTableData>
                        <SpeciesTableData>
                            <Link to={`/key/${toNodeData.id}`}>{toNodeData.id}</Link>
                        </SpeciesTableData>
                        <SpeciesTableData style={{
                            backgroundColor: imagesColor,
                        }}>
                        </SpeciesTableData>
                        <SpeciesTableData style={{
                            backgroundColor: fieldMarksColor,
                        }}>
                        </SpeciesTableData>
                        <SpeciesTableData style={{
                            backgroundColor: keyOutOptionData.text ? "lightgreen" : "lightred",
                        }}>
                        </SpeciesTableData>
                        <SpeciesTableData>
                            {nodeData.marks.map(mark => mark.id).join(", ")}
                        </SpeciesTableData>
                        <SpeciesTableData>
                            {toNodeData.taxon?.latinName}
                        </SpeciesTableData>
                    </tr>)
                })}
            </tbody>
        </Table>
    );
}
