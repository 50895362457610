import React, { useState } from "react";
import styled from "styled-components";
import { InteractiveMap } from "./interactiveMap";
import { Link, useSearchParams } from "react-router-dom";

const Table = styled.table`
    border-collapse: collapse;
`;

const SpeciesTableHeader = styled.th`
    border: 1px solid black;
    padding: 0.5em;
`;

const SpeciesTableData = styled.td`
    border: 1px solid black;
    padding: 0.5em;
`;

function SpeciesLink({ species_id, maplat, maplng, mapzoom }) {
    return <Link key={species_id} to={`/species/${species_id}/?maplat=${maplat}&maplng=${maplng}&mapzoom=${mapzoom}`}>{species_id}</Link>
}

export function QueryMap() {
    const [occurrences, setOccurrences] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const maplat = searchParams.get("maplat");
    const maplng = searchParams.get("maplng");
    const mapzoom = searchParams.get("mapzoom");
    const mapOnClick = async (map_current, e) => {
        const lat = e.lngLat.lat;
        const lon = e.lngLat.lng;
        const response = await fetch(`/occurrence_by_location?lat=${lat}&lon=${lon}`);
        const data = await response.json();
        let counts = {};
        data.forEach((d) => {
            if (counts[d.species_id]) {
                counts[d.species_id] += 1;
            } else {
                counts[d.species_id] = 1;
            }
        });
        const final = [];
        Object.keys(counts).forEach((key) => {
            final.push({species_id: key, count: counts[key]});
        });
        final.sort((a, b) => b.count - a.count);
        setOccurrences(final);
    }

    return <div>
        <InteractiveMap onClick={mapOnClick}/>
        <Table>
            <thead>
                <tr>
                    <SpeciesTableHeader>Species ID</SpeciesTableHeader>
                    <SpeciesTableHeader>Count</SpeciesTableHeader>
                </tr>
            </thead>
            <tbody>
                {occurrences.map((s) => {
                    return <tr key={s.species_id}>
                        <SpeciesTableData><SpeciesLink species_id={s.species_id} maplat={maplat} maplng={maplng} mapzoom={mapzoom} /></SpeciesTableData>
                        <SpeciesTableData>{s.count}</SpeciesTableData>
                    </tr>
                })}
            </tbody>
        </Table>
    </div>
}