import React, { useState, useEffect, useRef } from 'react';
import { useLocalStorage } from 'usehooks-ts'
import {
  createBrowserRouter,
  RouterProvider,
  useParams,
  Navigate,
} from "react-router-dom";
import { gql, useQuery, useMutation } from '@apollo/client';
import styled from 'styled-components';
import Ruler from "@scena/react-ruler";

import { KeyOutPath } from './KeyOut';
import { KeyOutOption } from './KeyOutOption';
import { SpeciesListElement } from './SpeciesListElement';
import { Species } from './Species';
import { Button } from "./button";
import { getPxPerCm, deviceNames } from './devices';
import { UploadPhoto } from './UploadPhoto';
import { AddFieldMark } from './AddFieldMark';
import { Taxon } from './Taxon';
import { SearchBox } from './SearchBox';
import { SpeciesTable } from './allSpecies';
import { NodesTable } from './allNodes';
import { AddOccurrenceFlag } from './AddOccurrenceFlag';
import { QueryMap } from './queryMap';
import { ImageFillViewer } from './ImageFillViewer';


const GET_NODE_DISPLAY = gql`
  query GetNodeForDisplay($nodeID: String!) {
    node(id: $nodeID) {
      id,
      taxon {
        id,
        taxonType,
        latinName,
        publication {
          author
        }
      },
      optionsFromHere {
        id,
        text,
        shortCode,
        images {
          id,
          image {
            url
          },
          description,
          fieldMarks {
            x1,
            x2,
            y1,
            y2,
            fieldMarkType
          },
          cropData {
            cropX,
            cropY,
            cropWidth,
            cropHeight
          }
        },
        toNode {
          id,
          shortCode,
          species {
            id,
            latinName
          },
          states {
            id,
          },
          taxon {
            id,
            taxonType,
            latinName
          }
        }
      },
      optionsLeadingHere {
        id,
        text,
        shortCode,
        toNode {
          id,
          shortCode
          taxon {
            id,
            latinName
          }
        },
        node {
          id
        }
      }
    }
  }
`;


const KeyWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;


const MarkAndRuler = ({ onClickRuler, setShowMark }) => {
  return (
    <div style={{
        display: "flex", 
        flexDirection: "row", 
        justifyContent: "space-between",
        borderTop: "1px solid black",
        borderBottom: "1px solid black",
    }}>
      <div style={{
        width: "50%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "18px",
        fontWeight: "bold",
        height: "32px",
        borderRight: "1px solid black",
        cursor: "pointer"
      }}
      onClick={setShowMark}>
        <div style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}>
          <span className="material-symbols-outlined" style={{ marginRight: "4px" }}>
            flag
          </span>
          <span>
            Mark
          </span>
        </div>
      </div>
      <div style={{
        width: "50%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "18px",
        fontWeight: "bold",
        height: "32px",
        cursor: "pointer"
      }} onClick={onClickRuler}>
        <div style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}>
          <span className="material-symbols-outlined" style={{ marginRight: "4px" }}>
            straighten
          </span>
          <span>Ruler</span>
        </div>
      </div>
    </div>
  )
}


const SideRuler = ({ hideRuler }) => {
  const ruler = useRef(null);
  const [ deviceModel, setDeviceModel ] = useLocalStorage("deviceModel", null);
  useEffect(() => {
    ruler.current.resize();
  }, []);
  const rulerlength = window.innerHeight;

  const pixels_per_cm = getPxPerCm(deviceModel);
  console.log(pixels_per_cm);

  const handleSetDeviceModel = (event) => {
    setDeviceModel(event.target.value);
  }

  return (
    <div style={{
      width: "8vw",
    }}>
      {(deviceModel === null) && 
        <Modal onClose={() => {
          hideRuler();
        }}>
          <div style={{
            backgroundColor: "white",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}>
            <p>Select your device so that we can get the ruler dimensions correct.</p>
            <select onChange={handleSetDeviceModel}>
              {deviceNames.map((device) => (
                <option key={device} value={device}>{device}</option>
              ))}
            </select>
          </div>
        </Modal>
      }
      <div style={{
        height: "8vw",
        width: rulerlength,
        borderTop: "1px solid black",
        transform: "rotate(270deg)",
        translate: "-100%",
        transformOrigin: "top right",
      }}>
        <Ruler type="horizontal" backgroundColor="white" lineColor="black" textColor="black" zoom={pixels_per_cm/10} unit={10} direction='end' ref={e => {
          ruler.current = e;
        }} textFormat={x => x === 0 ? "0mm" : x}/>
      </div>
    </div>
  );
}

const NodeSpeciesList = ({ species }) => {
  // const { loading, error, data } = useQuery(GET_NODE_SPECIES_LIST, {variables: {"nodeID": node_id}});
  // if (loading) return <p>Loading...</p>;
  // if (error) return <pre>{JSON.stringify(error)}</pre>;
  return (
    <div style={{
      backgroundColor: "white",
      width: "100%",
    }}>
      <h3 align="center">Species</h3>
      <ul>
        {species.map((opt) => 
          <SpeciesListElement key={opt.id} speciesData={opt} />
        )}
      </ul>
    </div>
  );
}

const Modal = ({ children, onClose }) => {
  return (
    <div style={{
      position: "fixed",
      inset: "0",
      backgroundColor: "rgba(255, 255, 255, 0.5)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      overflow: "scroll",
      zIndex: 100,
    }}>
      <Button style={{
        position: "absolute",
        top: "0",
        right: "0",
        margin: "4px",
      }} onClick={onClose}>Close</Button>
      { children }
    </div>
  );
}


const MARK_NODE = gql`
  mutation MarkNode($nodeID: String!, $markValue: String!, $markType: String!) {
    addMark(nodeId: $nodeID, markValue: $markValue, markType: $markType) {
      id
      markValue
      markType
    }
  }
`;


const GET_NODE_MARKS = gql`
  query GetNodeForDisplay($nodeID: String!) {
    node(id: $nodeID) {
      id,
      marks {
        id
        markValue
        markType
      }
    }
  }
`;



const Mark = () => {
  const { node_id } = useParams();
  const { loading, error, data } = useQuery(GET_NODE_MARKS, {variables: {"nodeID": node_id}});
  const [markNode, { loading: mutationLoading, error: mutationError, data: mutationData }] = useMutation(MARK_NODE);
  const [markValue, setMarkValue] = useState("");
  const [markType, setMarkType] = useState("Select...");

  if (mutationLoading) return <p>Loading...</p>;
  if (error || mutationError) return <pre>{JSON.stringify(error)}</pre>;

  const handleMarkNode = () => {
    markNode({
      variables: {
        nodeID: node_id,
        markValue: markValue,
        markType: markType,
      }
    });
  }


  return (
    <div style={{
      overflow: "scroll",
      backgroundColor: "white",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      padding: "25px 25px 0px 25px",
      borderBottom: "1px solid black",
    }}>
      <h3>New Mark</h3>
      <div style={{
        display: "flex",
        flexDirection: "column",
        width: "calc(100vw - 50px)",
        marginBottom: "8px",
      }}>
        <select onChange={(event) => setMarkType(event.target.value)} value={markType}>
          <option value="Select..." disabled={true}>Select...</option>
          <option value="Difficult or unclear">Difficult or unclear</option>
          <option value="Got it wrong">Got it wrong</option>
          <option value="Complete">Complete</option>
          <option value="Other issue/note">Other issue/note</option>
        </select>
        <textarea onChange={(event) => setMarkValue(event.target.value)} style={{
          marginTop: "8px",
          minHeight: "100px",
          fontFamily: "unset",
        }}></textarea>
      </div>
      <Button style={{
        width: "150px",
      }}
        onClick={handleMarkNode}>Submit</Button>
      <div>
        <h3>Previous Marks</h3>
        {loading ? <p>Loading...</p> : <>
          {data.node.marks.map((mark) => (
            <div key={mark.id} style={{
              width: "100%",
              borderBottom: "1px solid black",
              padding: "4px",
            }}>
              <h4>{mark.markType}</h4>
              <p>{mark.markValue}</p>
            </div>
          ))}
        </>}
      </div>
    </div>
  )
}



const Key = () => {
  const { node_id } = useParams();
  const [ speciesModalNodeID, setSpeciesModalNodeID ] = useState(null);
  const [markNodeID, setMarkNodeID] = useState(null);
  const [showRuler, setShowRuler] = useState(false);
  const [path, setPath] = useLocalStorage("path", []);
  const { loading, error, data } = useQuery(GET_NODE_DISPLAY, {variables: {"nodeID": node_id}});
  const opt0 = data?.node?.optionsFromHere[0];
  const opt1 = data?.node?.optionsFromHere[1];
  useEffect(() => {
    if (opt0 && opt1) {
      const pathIds = path.map((node) => node.id);
      if (pathIds.includes(opt0.id)) {
        setPath(path.slice(0, pathIds.indexOf(opt0.id)));
      }
      else if (pathIds.includes(opt1.id)) {
        setPath(path.slice(0, pathIds.indexOf(opt1.id)));
      }
      // in case the user hit forward to get back to a previously selected option,
      // this will figure out how to append that option to the path
      else {
        for (let opt of data.node.optionsLeadingHere) {
          if (path.length > 0 && opt.node.id === path[path.length - 1].toNode.id) {
            setPath([...path, opt]);
            break;
          } else if (path.length === 0 && opt.node.id === "root_1") {
            setPath([...path, opt]);
            break;
          }
        }
      }
    }
  }, [opt0, opt1, path, setPath]);
  if (loading) return <p>Loading...</p>;
  if (error) return <pre>{JSON.stringify(error)}</pre>;
  if (!data.node) return <p>Node not found</p>;
  const ruler = showRuler ? <SideRuler hideRuler={() => setShowRuler(false)} /> : null;

  return (
    <div style={{
      display: "flex",
      flexDirection: "row",
    }} >
      {speciesModalNodeID === opt0.toNode.id && <Modal onClose={() => setSpeciesModalNodeID(null)}>
        <NodeSpeciesList species={opt0.toNode.species}/>
      </Modal>}
      {speciesModalNodeID === opt1.toNode.id && <Modal onClose={() => setSpeciesModalNodeID(null)}>
        <NodeSpeciesList species={opt1.toNode.species}/>
      </Modal>}
      {markNodeID && <Modal onClose={() => setMarkNodeID(null)}><Mark node_id={markNodeID}/></Modal>}
      <KeyWrapper>
        <SearchBox />
        {/* <KeyOutPath /> */}
        <div style={{ paddingLeft: "10px" }}><Taxon taxon={data.node.taxon} /></div>
        <KeyOutOption setSpeciesModalNodeID={setSpeciesModalNodeID} data={opt0} />
        <MarkAndRuler setShowMark={() => setMarkNodeID(node_id)} onClickRuler={() => setShowRuler(!showRuler)} />
        <KeyOutOption setSpeciesModalNodeID={setSpeciesModalNodeID} data={opt1} />
      </KeyWrapper>
      {ruler}
    </div>
  );
}


const router = createBrowserRouter([
  {
    path: "/species",
    element: <SpeciesTable />,
  },
  {
    path: "/nodes",
    element: <NodesTable />,
  },
  {
    path: "/species/:id",
    element: <Species />,
  },
  {
    path: "/species/:id/images",
    element: <ImageFillViewer />,
  },
  {
    path: "/species/:id/upload_photo",
    element: <UploadPhoto />,
  },
  {
    path: "/key/option/:option_id/add_photo/:photo_id",
    element: <AddFieldMark />,
  },
  {
    path: "/key/:node_id",
    element: <Key />,
  },
  {
    path: "/key",
    element: <Navigate to="/key/FNA_key_to_sections_of_carex_1"/>,
  },
  {
    path: "/",
    element: <Navigate to="/key/FNA_key_to_sections_of_carex_1"/>,
  },
  {
    path: "/occurrence/:occurrence_id/flag",
    element: <AddOccurrenceFlag />,
  },
  {
    path: "/query_map",
    element: <QueryMap />,
  },
]);


function App() {
  useEffect(() => {
    // get current geolocation and put it into localStorage
    navigator.geolocation.getCurrentPosition((position) => {
      console.log(position)
      localStorage.setItem("lat", position.coords.latitude);
      localStorage.setItem("lon", position.coords.longitude);
    });
  }, []);
  return (
    <RouterProvider router={router} />
  );
}

export default App;
