import React from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';

import { Button, ButtonLink } from "./button";
import { KeyOutPaths } from './Species';


const GET_IMAGE = gql`
  query GetImage($imageID: String!) {
    image(id: $imageID) {
      id,
      url,
      entities {
        name
      }
      species {
        id,
        latinName,
        pathsToSection {
          id,
          text,
          node {
            id,
            shortCode,
          }
        },
        pathsToSpecies {
          id,
          text,
          node {
            id,
            shortCode,
          }
        }
      }
    }
  }
`;


const GET_TAG_OPTIONS = gql`
    query GetTagOptions {
        tagOptions {
            id,
            name,
        }
    }
`;


const TAG_IMAGE = gql`
  mutation TagImage($imageID: String!, $tagIDs: [String!]!) {
    tagImage(imageId: $imageID, tagIds: $tagIDs) {
      ok
    }
  }
`;


const AddTagsToImage = ({ image_id }) => {
  const [perigynium, setPerigynium] = React.useState(false);
  const [achene, setAchene] = React.useState(false);
  const [inflorescence, setInflorescence] = React.useState(false);
  const [sheath, setSheath] = React.useState(false);
  const [culm, setCulm] = React.useState(false);
  const [leaf, setLeaf] = React.useState(false);
  const [spike, setSpike] = React.useState(false);
  const [tagDropdownValue, setTagDropdownValue] = React.useState("Select...");
  const { loading, error, data } = useQuery(GET_TAG_OPTIONS);
  const [tagImage, { loading: mutationLoading, error: mutationError, data: mutationData }] = useMutation(TAG_IMAGE);
  if (loading) return <p>Loading...</p>;
  if (error) return <pre>{JSON.stringify(error)}</pre>;
  if (mutationLoading) return <p>Loading...</p>;
  if (mutationError) return <pre>{JSON.stringify(mutationError)}</pre>;
  if (mutationData) return <p>Image tagged successfully</p>;
  const tagOptions = data.tagOptions.map(e => e.name).sort();

  const handleDropdownChange = (e) => {
    setTagDropdownValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let tags = [];
    if (perigynium) tags.push("perigynium");
    if (achene) tags.push("achene")
    if (inflorescence) tags.push("inflorescence")
    if (sheath) tags.push("sheath");
    if (culm) tags.push("culm");
    if (leaf) tags.push("leaf");
    if (spike) tags.push("spike");
    if (tagDropdownValue !== "Select...") tags.push(tagDropdownValue);
    tagImage({variables: {
      "imageID": image_id,
      "tagIDs": tags
    }});
  }

  const handleDropdownCheckbox = (e) => {
    const toValue = tagDropdownValue === "Select...";
    if (!toValue) {
      setTagDropdownValue("Select...")
    }
  }

  return (
    <div>
    <h2>Add image tags</h2>
      <form id="imagetagform">
        <input type="checkbox" name="perigynium" checked={perigynium} onChange={e => setPerigynium(!perigynium)} />
        <label htmlFor="perigynium">Perigynium</label><br />
        <input type="checkbox" name="achene" checked={achene} onChange={e => setAchene(!achene)} />
        <label htmlFor="achene">Achene</label><br />
        <input type="checkbox" name="inflorescence" checked={inflorescence} onChange={e => setInflorescence(!inflorescence)} />
        <label htmlFor="inflorescence">Inflorescence</label><br />
        <input type="checkbox" name="sheath" checked={sheath} onChange={e => setSheath(!sheath)} />
        <label htmlFor="sheath">Sheath</label><br />
        <input type="checkbox" name="plant-form" checked={culm} onChange={e => setCulm(!culm)} />
        <label htmlFor="plant-form">Plant form</label><br />
        <input type="checkbox" name="spike" checked={spike} onChange={e => setSpike(!spike)} />
        <label htmlFor="spike">Spike</label><br />
        <input type="checkbox" name="leaf" checked={leaf} onChange={e => setLeaf(!leaf)} />
        <label htmlFor="leaf">Leaf</label><br />
        <input type="checkbox" name="other" checked={tagDropdownValue !== "Select..."} disabled={tagDropdownValue === "Select..."} onChange={handleDropdownCheckbox} />
        <label htmlFor="other">
          Other: <select value={tagDropdownValue} onChange={handleDropdownChange}>
            <option key="select..." disabled={true}>Select...</option>
            {
              tagOptions.map((to) => <option key={to} value={to}>{to}</option>)
            }
          </select>
        </label><br />
        <input type="submit" value="Submit" onClick={handleSubmit} />
      </form>
    </div>
  );
}


const KeyOutPathBlock = ({ image_id, path, keyoutidx }) => {
  const items = path.map( (item, idx) => (
    <li style={{
      listStyleType: "none",
      padding: "2px",
    }} key={`${item.id}-${keyoutidx}-${idx}`}>
      <a href={`/key/option/${item.id}/add_photo/${image_id}`}><b>{item.id}</b></a>: {item.text}
    </li>
  ));
  return (
    <div style={{
      maxWidth: "500px",
      borderLeft: "1px solid black",
      borderBottom: "1px solid black",
      padding: "10px", 
    }}>
      {items}
    </div>
  )
}


export const EditImage = ({ image_id, close }) => {
  // const { image_id } = useParams();
  console.log(image_id);
  const [showPaths, setShowPaths] = React.useState(false);
  const { loading, error, data } = useQuery(GET_IMAGE, {variables: {"imageID": image_id}});
  if (loading) return <p>Loading...</p>;
  if (error) return <pre>{JSON.stringify(error)}</pre>;
  if (!data.image) return <p>Image not found</p>;
  const species = data.image.species;
  const keyOutOptions = species.pathsToSpecies.concat(species.pathsToSection);


  return (
    <div>
      <h2>Add image to key</h2>
      <img src={data.image.url} width="200px" alt={species.latinName} />
      <p>Species: {species.latinName}</p>
      <h4>Key out options</h4>
      {/* <select value={keyOutOptionID} onChange={(e) => setKeyOutOptionID(e.target.value)}>
        <option key="Select..." value="Select..." disabled={true}>Select...</option>
        {keyOutOptions.map((option) => (
          <option key={option.id} value={option.id}>{option.id}: {option.text}</option>
        ))}
      </select> */}
      {showPaths && <Button onClick={() => setShowPaths(false)}>Hide paths</Button>}
      {showPaths ?
        <div style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-start",
        }}>
          {/* {keyOutOptions.map((path, idx) => <KeyOutPathBlock image_id={data.image.id} key={idx} path={path} keyoutidx={idx} />)} */}
          <KeyOutPaths speciesId={species.id} coupletLinkConstructor={(nodeId => `/key/option/${nodeId}/add_photo/${image_id}`)} />
        </div>
      : <Button onClick={() => setShowPaths(true)}>Show paths</Button>}
      <h4>Tags</h4>
      <ul>
        {
          data.image.entities.map(e => <li key={e.name}>{e.name}</li>)
        }
      </ul>
      <AddTagsToImage image_id={image_id} />
      <Button style={{marginTop: "50px"}} onClick={close}>Close</Button>
    </div>
  );
}