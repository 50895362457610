import React, { useRef, useEffect } from 'react';

export function KeyImage({ arrow, ruler, crop, imgSrc, ImageMaxWidth, ImageMaxHeight, fitToSquare = false, dpr = window.devicePixelRatio || 1}) {
    const canvasRef = useRef(null);
    if ( (typeof ImageMaxWidth !== "number") || (typeof ImageMaxHeight !== "number") ) {
        throw new Error('ImageMaxWidth and ImageMaxHeight must be provided as numbers');
    }

    console.log("height", ImageMaxHeight, ImageMaxWidth, fitToSquare)
    console.log('crop', crop)
    console.log('imgSrc', imgSrc)
    console.log('arrow', arrow)
    console.log('ruler', ruler)


    const drawImage = () => {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
  
      let img = new Image();
      img.onload = function() {
        if (!crop) {
            crop = {};
            crop.w = img.width;
            crop.h = img.height;
            crop.x = 0;
            crop.y = 0;
        }

        const cropScaleWidth = ImageMaxWidth / crop.w;
        const cropScaleHeight = ImageMaxHeight / crop.h;
        const cropScale = fitToSquare ? Math.max(cropScaleWidth, cropScaleHeight) : Math.min(cropScaleWidth, cropScaleHeight);
        const width = crop.w * cropScale;
        const height = crop.h * cropScale;
        
        if (fitToSquare) {
            canvas.width = Math.min(width * dpr, height * dpr);
            canvas.height = Math.min(width * dpr, height * dpr);
            canvas.style.width = `${Math.min(width, height)}px`;
            canvas.style.height = `${Math.min(width, height)}px`;
        } else {
            canvas.width = width * dpr;
            canvas.height = height * dpr;
            canvas.style.width = `${width}px`;
            canvas.style.height = `${height}px`;
        }
        context.scale(dpr, dpr);
  
        if (crop) {
            context.drawImage(
                img, 
                crop.x, crop.y, crop.w, crop.h, 
                0, 0, width, height
            );
        } else {
            context.drawImage(
                img,
                0, 0, img.width, img.height,
                0, 0, width, height
            );
        }

        if (ImageMaxWidth < 150 && ImageMaxHeight < 150) {
            return;
        }

        if (ruler) {
            const dx = (ruler.x2 - ruler.x1);
            const dy = (ruler.y2 - ruler.y1);
            const angle = Math.atan2(dy, dx);
            const lineCapLength = height > 300 ? 7.5 : 5;
            
            context.beginPath();
            context.moveTo((ruler.x1 - crop.x) * cropScale, (ruler.y1 - crop.y) * cropScale);
            context.lineTo((ruler.x2 - crop.x) * cropScale, (ruler.y2 - crop.y) * cropScale);
    
            // Draw perpendicular lines at the ends of the main line
            context.moveTo((ruler.x1 - crop.x) * cropScale + lineCapLength * Math.cos(angle + Math.PI/2), (ruler.y1 - crop.y) * cropScale + lineCapLength * Math.sin(angle + Math.PI/2));
            context.lineTo((ruler.x1 - crop.x) * cropScale + lineCapLength * Math.cos(angle - Math.PI/2), (ruler.y1 - crop.y) * cropScale + lineCapLength * Math.sin(angle - Math.PI/2));
    
            context.moveTo((ruler.x2 - crop.x) * cropScale + lineCapLength * Math.cos(angle + Math.PI/2), (ruler.y2 - crop.y) * cropScale + lineCapLength * Math.sin(angle + Math.PI/2));
            context.lineTo((ruler.x2 - crop.x) * cropScale + lineCapLength * Math.cos(angle - Math.PI/2), (ruler.y2 - crop.y) * cropScale + lineCapLength * Math.sin(angle - Math.PI/2));
    
            context.strokeStyle = 'red';
            context.lineWidth = height > 300 ? 2 : 1.5;
            context.stroke();
        }
        if (arrow) {
            const dx = (arrow.x2 - arrow.x1);
            const dy = (arrow.y2 - arrow.y1);
            const angle = Math.atan2(dy, dx);
            const headlen = 10;
            const headAngle = Math.PI / 6;
    
            context.beginPath();
            context.moveTo((arrow.x1 - crop.x) * cropScale, (arrow.y1 - crop.y) * cropScale);
            console.log("start", (arrow.x1 - crop.x) * cropScale, (arrow.y1 - crop.y) * cropScale);
            context.lineTo((arrow.x2 - crop.x) * cropScale, (arrow.y2 - crop.y) * cropScale);
            console.log("end", (arrow.x2 - crop.x) * cropScale, (arrow.y2 - crop.y) * cropScale);
            context.lineTo((arrow.x2 - crop.x) * cropScale - headlen * Math.cos(angle - headAngle), (arrow.y2 - crop.y) * cropScale - headlen * Math.sin(angle - headAngle));
            context.moveTo((arrow.x2 - crop.x) * cropScale, (arrow.y2 - crop.y) * cropScale);
            context.lineTo((arrow.x2 - crop.x) * cropScale - headlen * Math.cos(angle + headAngle), (arrow.y2 - crop.y) * cropScale - headlen * Math.sin(angle + headAngle));
            
            context.strokeStyle = 'red';
            context.lineWidth = 2;
            context.stroke();
        }
      }
      img.src = imgSrc;
    }
  
    useEffect(drawImage, [arrow, ruler, crop, imgSrc, ImageMaxWidth, ImageMaxHeight]);
  
    return (
      <div id="c">
        <canvas ref={canvasRef} />
      </div>
    );
}