export const getPxPerCm = (device_name) => {
    const device = devices[device_name];
    if (!device) return null;
    const diagonal_in_in = device.physical_size_in;
    const diagonal_in_cm = diagonal_in_in * 2.54;
    const aspect_ratio = device.px_height / device.px_width;
    const width_in_cm = diagonal_in_cm / Math.sqrt(1 + Math.pow(aspect_ratio, 2));
    return window.screen.width / width_in_cm;
}

const devices = {
    "Acer Chromebook": {
      "persist": "Acer Chromebook",
      "operating_system": "Chrome",
      "physical_size_in": "11.6",
      "physical_size_cm": "29.0",
      "px_width": "1366",
      "px_height": "768",
      "device_width": "1366",
      "ppi": "135",
      "px_density": "100% MDPI",
      "aspect_ratio": "16 : 9"
    },
    "Google Chromebook Pixel": {
      "persist": "Google Chromebook Pixel",
      "operating_system": "Chrome",
      "physical_size_in": "12.9",
      "physical_size_cm": "33.0",
      "px_width": "2560",
      "px_height": "1700",
      "device_width": "1280",
      "ppi": "239",
      "px_density": "200% XHDPI",
      "aspect_ratio": "3 : 2"
    },
    "Samsung Chromebook": {
      "persist": "Samsung Chromebook",
      "operating_system": "Chrome",
      "physical_size_in": "11.6",
      "physical_size_cm": "29.0",
      "px_width": "1366",
      "px_height": "768",
      "device_width": "1366",
      "ppi": "135",
      "px_density": "100% MDPI",
      "aspect_ratio": "16 : 9"
    },
    "Apple Cinema Display 30&quot;": {
      "persist": "Apple Cinema Display 30&quot;",
      "operating_system": "OS X",
      "physical_size_in": "30.0",
      "physical_size_cm": "76.0",
      "px_width": "2560",
      "px_height": "1600",
      "device_width": "2560",
      "ppi": "100",
      "px_density": "100% MDPI",
      "aspect_ratio": "8 : 5"
    },
    "Apple Thunderbolt Display 27&quot;": {
      "persist": "Apple Thunderbolt Display 27&quot;",
      "operating_system": "OS X",
      "physical_size_in": "27.0",
      "physical_size_cm": "69.0",
      "px_width": "2560",
      "px_height": "1440",
      "device_width": "2560",
      "ppi": "109",
      "px_density": "100% MDPI",
      "aspect_ratio": "16 : 9"
    },
    "Apple iMac 21.5-inch (Late 2009 - Late 2012)": {
      "persist": "Apple iMac 21.5-inch (Late 2009 - Late 2012)",
      "operating_system": "OS X",
      "physical_size_in": "21.5",
      "physical_size_cm": "55.0",
      "px_width": "1921",
      "px_height": "1080",
      "device_width": "1921",
      "ppi": "102",
      "px_density": "100% MDPI",
      "aspect_ratio": "16 : 9"
    },
    "Apple iMac 27-inch (Late 2009 - Late 2012)": {
      "persist": "Apple iMac 27-inch (Late 2009 - Late 2012)",
      "operating_system": "OS X",
      "physical_size_in": "27.0",
      "physical_size_cm": "69.0",
      "px_width": "2560",
      "px_height": "1440",
      "device_width": "2560",
      "ppi": "109",
      "px_density": "100% MDPI",
      "aspect_ratio": "16 : 9"
    },
    "Apple MacBook Air 11-inch (Late 2010 - Mid 2012)": {
      "persist": "Apple MacBook Air 11-inch (Late 2010 - Mid 2012)",
      "operating_system": "OS X",
      "physical_size_in": "11.6",
      "physical_size_cm": "29.0",
      "px_width": "1366",
      "px_height": "768",
      "device_width": "1366",
      "ppi": "135",
      "px_density": "100% MDPI",
      "aspect_ratio": "16 : 9"
    },
    "Apple MacBook Air 13-inch (Late 2010 - Mid 2012)": {
      "persist": "Apple MacBook Air 13-inch (Late 2010 - Mid 2012)",
      "operating_system": "OS X",
      "physical_size_in": "13.3",
      "physical_size_cm": "34.0",
      "px_width": "1440",
      "px_height": "900",
      "device_width": "1440",
      "ppi": "128",
      "px_density": "100% MDPI",
      "aspect_ratio": "8 : 5"
    },
    "Apple MacBook Pro 13-inch (Mid 2009 - Mid 2012)": {
      "persist": "Apple MacBook Pro 13-inch (Mid 2009 - Mid 2012)",
      "operating_system": "OS X",
      "physical_size_in": "13.3",
      "physical_size_cm": "34.0",
      "px_width": "1280",
      "px_height": "800",
      "device_width": "1280",
      "ppi": "113",
      "px_density": "100% MDPI",
      "aspect_ratio": "8 : 5"
    },
    "Apple MacBook Pro 13-inch (Retina display)": {
      "persist": "Apple MacBook Pro 13-inch (Retina display)",
      "operating_system": "OS X",
      "physical_size_in": "13.3",
      "physical_size_cm": "34.0",
      "px_width": "2560",
      "px_height": "1600",
      "device_width": "1280",
      "ppi": "227",
      "px_density": "200% XHDPI",
      "aspect_ratio": "8 : 5"
    },
    "Apple MacBook Pro 15-inch (Early 2006 - Mid 2012)": {
      "persist": "Apple MacBook Pro 15-inch (Early 2006 - Mid 2012)",
      "operating_system": "OS X",
      "physical_size_in": "15.4",
      "physical_size_cm": "39.0",
      "px_width": "1440",
      "px_height": "900",
      "device_width": "1440",
      "ppi": "110",
      "px_density": "100% MDPI",
      "aspect_ratio": "8 : 5"
    },
    "Apple MacBook Pro 15-inch (Hi-Res screen)": {
      "persist": "Apple MacBook Pro 15-inch (Hi-Res screen)",
      "operating_system": "OS X",
      "physical_size_in": "15.4",
      "physical_size_cm": "39.0",
      "px_width": "1680",
      "px_height": "1050",
      "device_width": "1680",
      "ppi": "128",
      "px_density": "100% MDPI",
      "aspect_ratio": "8 : 5"
    },
    "Apple MacBook Pro 15-inch (Retina display)": {
      "persist": "Apple MacBook Pro 15-inch (Retina display)",
      "operating_system": "OS X",
      "physical_size_in": "15.4",
      "physical_size_cm": "39.0",
      "px_width": "2880",
      "px_height": "1800",
      "device_width": "1440",
      "ppi": "220",
      "px_density": "200% XHDPI",
      "aspect_ratio": "8 : 5"
    },
    "Apple MacBook Pro 17-inch (Mid/Late 2007 - Late 2011)": {
      "persist": "Apple MacBook Pro 17-inch (Mid/Late 2007 - Late 2011)",
      "operating_system": "OS X",
      "physical_size_in": "17.3",
      "physical_size_cm": "44.0",
      "px_width": "1920",
      "px_height": "1200",
      "device_width": "1920",
      "ppi": "131",
      "px_density": "100% MDPI",
      "aspect_ratio": "8 : 5"
    },
    "Panasonic BT-LH2170": {
      "persist": "Panasonic BT-LH2170",
      "operating_system": "SDI",
      "physical_size_in": "21.5",
      "physical_size_cm": "546.1",
      "px_width": "1080",
      "px_height": "1920",
      "device_width": "-",
      "ppi": "149",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Panasonic BT-LH910GJ": {
      "persist": "Panasonic BT-LH910GJ",
      "operating_system": "SDI",
      "physical_size_in": "9.0",
      "physical_size_cm": "22.9",
      "px_width": "768",
      "px_height": "1280",
      "device_width": "-",
      "ppi": "None",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Panasonic BT-4LH310P": {
      "persist": "Panasonic BT-4LH310P",
      "operating_system": "SDI",
      "physical_size_in": "31.0",
      "physical_size_cm": "78.7",
      "px_width": "2160",
      "px_height": "4096",
      "device_width": "-",
      "ppi": "149",
      "px_density": "N/A",
      "aspect_ratio": "17 : 9"
    },
    "Panasonic BT-LH1850": {
      "persist": "Panasonic BT-LH1850",
      "operating_system": "SDI",
      "physical_size_in": "18.5",
      "physical_size_cm": "47.0",
      "px_width": "768",
      "px_height": "1366",
      "device_width": "-",
      "ppi": "None",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Asus K55N-DS81 15.6 in": {
      "persist": "Asus K55N-DS81 15.6 in",
      "operating_system": "Windows",
      "physical_size_in": "15.6",
      "physical_size_cm": "40.0",
      "px_width": "1366",
      "px_height": "768",
      "device_width": "1366",
      "ppi": "100",
      "px_density": "100% MDPI",
      "aspect_ratio": "16 : 9"
    },
    "Asus PB287Q": {
      "persist": "Asus PB287Q",
      "operating_system": "Windows",
      "physical_size_in": "28.0",
      "physical_size_cm": "71.1",
      "px_width": "3840",
      "px_height": "2160",
      "device_width": "-",
      "ppi": "157",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Dell Inspiron 14 series 36&quot;": {
      "persist": "Dell Inspiron 14 series 36&quot;",
      "operating_system": "Windows",
      "physical_size_in": "14.1",
      "physical_size_cm": "36.0",
      "px_width": "1600",
      "px_height": "900",
      "device_width": "1600",
      "ppi": "130",
      "px_density": "100% MDPI",
      "aspect_ratio": "16 : 9"
    },
    "Dell S2340M 23&quot;": {
      "persist": "Dell S2340M 23&quot;",
      "operating_system": "Windows",
      "physical_size_in": "23.0",
      "physical_size_cm": "58.0",
      "px_width": "1920",
      "px_height": "1080",
      "device_width": "1920",
      "ppi": "96",
      "px_density": "100% MDPI",
      "aspect_ratio": "16 : 9"
    },
    "Dell U2713HM 27&quot;": {
      "persist": "Dell U2713HM 27&quot;",
      "operating_system": "Windows",
      "physical_size_in": "27.0",
      "physical_size_cm": "69.0",
      "px_width": "2560",
      "px_height": "1440",
      "device_width": "2560",
      "ppi": "109",
      "px_density": "100% MDPI",
      "aspect_ratio": "16 : 9"
    },
    "Dell UltraSharp U2412M 24&quot;": {
      "persist": "Dell UltraSharp U2412M 24&quot;",
      "operating_system": "Windows",
      "physical_size_in": "24.0",
      "physical_size_cm": "61.0",
      "px_width": "1920",
      "px_height": "1200",
      "device_width": "1920",
      "ppi": "94",
      "px_density": "100% MDPI",
      "aspect_ratio": "8 : 5"
    },
    "Dell UltraSharp U2711 27&quot;": {
      "persist": "Dell UltraSharp U2711 27&quot;",
      "operating_system": "Windows",
      "physical_size_in": "27.0",
      "physical_size_cm": "69.0",
      "px_width": "2560",
      "px_height": "1440",
      "device_width": "2560",
      "ppi": "109",
      "px_density": "100% MDPI",
      "aspect_ratio": "16 : 9"
    },
    "Dell UltraSharp U3011 30&quot;": {
      "persist": "Dell UltraSharp U3011 30&quot;",
      "operating_system": "Windows",
      "physical_size_in": "30.0",
      "physical_size_cm": "76.0",
      "px_width": "2560",
      "px_height": "1600",
      "device_width": "2560",
      "ppi": "100",
      "px_density": "100% MDPI",
      "aspect_ratio": "8 : 5"
    },
    "Dell UltraSharp UZ2315H": {
      "persist": "Dell UltraSharp UZ2315H",
      "operating_system": "Windows",
      "physical_size_in": "23.0",
      "physical_size_cm": "58.4",
      "px_width": "1920",
      "px_height": "1080",
      "device_width": "-",
      "ppi": "96",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "HP Pavilion G6-2235us 15.6&quot; Laptop (2...": {
      "persist": "HP Pavilion G6-2235us 15.6&quot; Laptop (2...",
      "operating_system": "Windows",
      "physical_size_in": "15.6",
      "physical_size_cm": "40.0",
      "px_width": "1366",
      "px_height": "768",
      "device_width": "1366",
      "ppi": "100",
      "px_density": "100% MDPI",
      "aspect_ratio": "16 : 9"
    },
    "HP Dreamcolor Z27x": {
      "persist": "HP Dreamcolor Z27x",
      "operating_system": "Windows",
      "physical_size_in": "27.0",
      "physical_size_cm": "68.6",
      "px_width": "2560",
      "px_height": "1440",
      "device_width": "-",
      "ppi": "109",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Lenovo Thinkpad X230": {
      "persist": "Lenovo Thinkpad X230",
      "operating_system": "Windows",
      "physical_size_in": "12.5",
      "physical_size_cm": "32.0",
      "px_width": "1366",
      "px_height": "768",
      "device_width": "1366",
      "ppi": "125",
      "px_density": "100% MDPI",
      "aspect_ratio": "16 : 9"
    },
    "Microsoft Surface Book": {
      "persist": "Microsoft Surface Book",
      "operating_system": "Windows",
      "physical_size_in": "13.5",
      "physical_size_cm": "34.3",
      "px_width": "3000",
      "px_height": "2000",
      "device_width": "-",
      "ppi": "267",
      "px_density": "N/A",
      "aspect_ratio": "2 : 3"
    },
    "Panasonic BT-4LH310 4K LCD Monitor": {
      "persist": "Panasonic BT-4LH310 4K LCD Monitor",
      "operating_system": "",
      "physical_size_in": "10.0",
      "physical_size_cm": "25.4",
      "px_width": "2160",
      "px_height": "3840",
      "device_width": "-",
      "ppi": "441",
      "px_density": "N/A",
      "aspect_ratio": "17 : 9"
    },
    "Panasonic BT-LH910 LCD Video Monitor": {
      "persist": "Panasonic BT-LH910 LCD Video Monitor",
      "operating_system": "",
      "physical_size_in": "9.0",
      "physical_size_cm": "23.0",
      "px_width": "768",
      "px_height": "1280",
      "device_width": "-",
      "ppi": "320",
      "px_density": "N/A",
      "aspect_ratio": "15 : 9"
    },
    "Apple MacBook 12-inch": {
      "persist": "Apple MacBook 12-inch",
      "operating_system": "OS X ",
      "physical_size_in": "12.0",
      "physical_size_cm": "30.5",
      "px_width": "2304",
      "px_height": "1440",
      "device_width": "-",
      "ppi": "226",
      "px_density": "N/A",
      "aspect_ratio": "5 : 8"
    },
    "Apple MacBook Air 11-inch": {
      "persist": "Apple MacBook Air 11-inch",
      "operating_system": "OS X",
      "physical_size_in": "11.6",
      "physical_size_cm": "29.5",
      "px_width": "1366",
      "px_height": "768",
      "device_width": "-",
      "ppi": "135",
      "px_density": "N/A",
      "aspect_ratio": "5 : 8"
    },
    "Apple MacBook Air 13-inch": {
      "persist": "Apple MacBook Air 13-inch",
      "operating_system": "OS X ",
      "physical_size_in": "13.3",
      "physical_size_cm": "33.8",
      "px_width": "1440",
      "px_height": "900",
      "device_width": "-",
      "ppi": "128",
      "px_density": "N/A",
      "aspect_ratio": "5 : 8"
    },
    "Apple MacBook Pro 13-inch": {
      "persist": "Apple MacBook Pro 13-inch",
      "operating_system": "OS X ",
      "physical_size_in": "13.3",
      "physical_size_cm": "33.8",
      "px_width": "2560",
      "px_height": "1600",
      "device_width": "-",
      "ppi": "227",
      "px_density": "N/A",
      "aspect_ratio": "5 : 8"
    },
    "Apple MacBook Pro 15-inch": {
      "persist": "Apple MacBook Pro 15-inch",
      "operating_system": "OS X ",
      "physical_size_in": "15.4",
      "physical_size_cm": "39.1",
      "px_width": "2880",
      "px_height": "1800",
      "device_width": "-",
      "ppi": "220",
      "px_density": "N/A",
      "aspect_ratio": "5 : 8"
    },
    "Apple iMac 21.5-inch (Retina 4K Display)": {
      "persist": "Apple iMac 21.5-inch (Retina 4K Display)",
      "operating_system": "OS X ",
      "physical_size_in": "21.5",
      "physical_size_cm": "54.6",
      "px_width": "4096",
      "px_height": "2304",
      "device_width": "-",
      "ppi": "219",
      "px_density": "N/A",
      "aspect_ratio": "5 : 8"
    },
    "Apple iMac 27-inch (Retina 5K Display)": {
      "persist": "Apple iMac 27-inch (Retina 5K Display)",
      "operating_system": "OS X ",
      "physical_size_in": "27.0",
      "physical_size_cm": "68.6",
      "px_width": "5120",
      "px_height": "2880",
      "device_width": "-",
      "ppi": "218",
      "px_density": "N/A",
      "aspect_ratio": "5 : 8"
    },
    "Acer S277HK": {
      "persist": "Acer S277HK",
      "operating_system": "Windows",
      "physical_size_in": "27.0",
      "physical_size_cm": "68.6",
      "px_width": "3840",
      "px_height": "2160",
      "device_width": "-",
      "ppi": "163",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Asus ZenBook UX305": {
      "persist": "Asus ZenBook UX305",
      "operating_system": "Windows",
      "physical_size_in": "13.3",
      "physical_size_cm": "33.8",
      "px_width": "1920",
      "px_height": "1080",
      "device_width": "-",
      "ppi": "276",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Asus Chrombook Flip": {
      "persist": "Asus Chrombook Flip",
      "operating_system": "Windows ",
      "physical_size_in": "10.1",
      "physical_size_cm": "25.7",
      "px_width": "1280",
      "px_height": "800",
      "device_width": "-",
      "ppi": "150",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Asus ROG G751JL-BSI7T28": {
      "persist": "Asus ROG G751JL-BSI7T28",
      "operating_system": "Windows",
      "physical_size_in": "17.3",
      "physical_size_cm": "43.9",
      "px_width": "1920",
      "px_height": "1080",
      "device_width": "-",
      "ppi": "127",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Dell XPS 13": {
      "persist": "Dell XPS 13",
      "operating_system": "Windows",
      "physical_size_in": "13.3",
      "physical_size_cm": "33.8",
      "px_width": "1920",
      "px_height": "1080",
      "device_width": "-",
      "ppi": "276",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Dell Inspiron 15 5000 Series": {
      "persist": "Dell Inspiron 15 5000 Series",
      "operating_system": "Windows",
      "physical_size_in": "15.6",
      "physical_size_cm": "39.6",
      "px_width": "1366",
      "px_height": "768",
      "device_width": "-",
      "ppi": "100",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "ViewSonic VX2452mh": {
      "persist": "ViewSonic VX2452mh",
      "operating_system": "Windows",
      "physical_size_in": "24.0",
      "physical_size_cm": "61.0",
      "px_width": "1920",
      "px_height": "1080",
      "device_width": "-",
      "ppi": "94",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Alcatel One Touch Idol Ultra": {
      "persist": "Alcatel One Touch Idol Ultra",
      "operating_system": "Android",
      "physical_size_in": "4.7",
      "physical_size_cm": "11.9",
      "px_width": "720",
      "px_height": "1280",
      "device_width": "360",
      "ppi": "316",
      "px_density": "200% XHDPI",
      "aspect_ratio": "9 : 16"
    },
    "Alcatel One Touch Ultra 995": {
      "persist": "Alcatel One Touch Ultra 995",
      "operating_system": "Android",
      "physical_size_in": "4.3",
      "physical_size_cm": "10.9",
      "px_width": "480",
      "px_height": "800",
      "device_width": "320",
      "ppi": "217",
      "px_density": "150% HDPI",
      "aspect_ratio": "3 : 5"
    },
    "Asus Zenfone Max ZC550KL": {
      "persist": "Asus Zenfone Max ZC550KL",
      "operating_system": "Android",
      "physical_size_in": "5.5",
      "physical_size_cm": "14.0",
      "px_width": "1280",
      "px_height": "720",
      "device_width": "-",
      "ppi": "267",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "BlackBerry BlackBerry Priv": {
      "persist": "BlackBerry BlackBerry Priv",
      "operating_system": "Android",
      "physical_size_in": "5.4",
      "physical_size_cm": "14.0",
      "px_width": "2560",
      "px_height": "1440",
      "device_width": "-",
      "ppi": "540",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "HTC Desire": {
      "persist": "HTC Desire",
      "operating_system": "Android",
      "physical_size_in": "3.7",
      "physical_size_cm": "9.4",
      "px_width": "480",
      "px_height": "800",
      "device_width": "320",
      "ppi": "252",
      "px_density": "150% HDPI",
      "aspect_ratio": "3 : 5"
    },
    "HTC Desire HD": {
      "persist": "HTC Desire HD",
      "operating_system": "Android",
      "physical_size_in": "4.3",
      "physical_size_cm": "11.0",
      "px_width": "480",
      "px_height": "800",
      "device_width": "320",
      "ppi": "217",
      "px_density": "150% HDPI",
      "aspect_ratio": "3 : 5"
    },
    "HTC Evo": {
      "persist": "HTC Evo",
      "operating_system": "Android",
      "physical_size_in": "4.3",
      "physical_size_cm": "11.0",
      "px_width": "480",
      "px_height": "800",
      "device_width": "320",
      "ppi": "217",
      "px_density": "150% HDPI",
      "aspect_ratio": "3 : 5"
    },
    "HTC One": {
      "persist": "HTC One",
      "operating_system": "Android",
      "physical_size_in": "4.7",
      "physical_size_cm": "12.0",
      "px_width": "1080",
      "px_height": "1920",
      "device_width": "360",
      "ppi": "468",
      "px_density": "300% XXHDPI",
      "aspect_ratio": "9 : 16"
    },
    "HTC One M9": {
      "persist": "HTC One M9",
      "operating_system": "Android",
      "physical_size_in": "5.0",
      "physical_size_cm": "12.7",
      "px_width": "1080",
      "px_height": "1920",
      "device_width": "-",
      "ppi": "441",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "HTC One M8": {
      "persist": "HTC One M8",
      "operating_system": "Android",
      "physical_size_in": "5.0",
      "physical_size_cm": "12.7",
      "px_width": "1080",
      "px_height": "1920",
      "device_width": "-",
      "ppi": "441",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Huawei P8": {
      "persist": "Huawei P8",
      "operating_system": "Android",
      "physical_size_in": "5.2",
      "physical_size_cm": "13.2",
      "px_width": "1080",
      "px_height": "1920",
      "device_width": "-",
      "ppi": "424",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "LG Nexus 4": {
      "persist": "LG Nexus 4",
      "operating_system": "Android",
      "physical_size_in": "4.7",
      "physical_size_cm": "12.0",
      "px_width": "768",
      "px_height": "1280",
      "device_width": "384",
      "ppi": "320",
      "px_density": "200% XHDPI",
      "aspect_ratio": "3 : 5"
    },
    "LG Nexus 5X": {
      "persist": "LG Nexus 5X",
      "operating_system": "Android",
      "physical_size_in": "5.2",
      "physical_size_cm": "13.2",
      "px_width": "1080",
      "px_height": "1920",
      "device_width": "-",
      "ppi": "424",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "LG G4": {
      "persist": "LG G4",
      "operating_system": "Android",
      "physical_size_in": "5.5",
      "physical_size_cm": "14.0",
      "px_width": "1440",
      "px_height": "2560",
      "device_width": "-",
      "ppi": "538",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "LG G3": {
      "persist": "LG G3",
      "operating_system": "Android",
      "physical_size_in": "5.5",
      "physical_size_cm": "12.7",
      "px_width": "1440",
      "px_height": "2560",
      "device_width": "-",
      "ppi": "538",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "LG V10": {
      "persist": "LG V10",
      "operating_system": "Android",
      "physical_size_in": "5.7",
      "physical_size_cm": "14.5",
      "px_width": "1440",
      "px_height": "2560",
      "device_width": "-",
      "ppi": "515",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "LG Nexus 4 AR": {
      "persist": "LG Nexus 4 AR",
      "operating_system": "Android",
      "physical_size_in": "4.7",
      "physical_size_cm": "11.9",
      "px_width": "768",
      "px_height": "1280",
      "device_width": "-",
      "ppi": "318",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "LG Phoenix 2": {
      "persist": "LG Phoenix 2",
      "operating_system": "Android",
      "physical_size_in": "2.5",
      "physical_size_cm": "11.1",
      "px_width": "720",
      "px_height": "1280",
      "device_width": "-",
      "ppi": "294",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "LG GX F310L": {
      "persist": "LG GX F310L",
      "operating_system": "Android",
      "physical_size_in": "5.5",
      "physical_size_cm": "14.0",
      "px_width": "1080",
      "px_height": "1920",
      "device_width": "-",
      "ppi": "401",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Motorola Droid": {
      "persist": "Motorola Droid",
      "operating_system": "Android",
      "physical_size_in": "3.7",
      "physical_size_cm": "9.4",
      "px_width": "480",
      "px_height": "854",
      "device_width": "320",
      "ppi": "265",
      "px_density": "150% HDPI",
      "aspect_ratio": "9 : 16"
    },
    "Motorola Droid 3 &amp; 4": {
      "persist": "Motorola Droid 3 &amp; 4",
      "operating_system": "Android",
      "physical_size_in": "4.0",
      "physical_size_cm": "10.0",
      "px_width": "540",
      "px_height": "960",
      "device_width": "360",
      "ppi": "280",
      "px_density": "150% HDPI",
      "aspect_ratio": "9 : 16"
    },
    "Motorola Droid Razr": {
      "persist": "Motorola Droid Razr",
      "operating_system": "Android",
      "physical_size_in": "4.3",
      "physical_size_cm": "11.0",
      "px_width": "540",
      "px_height": "960",
      "device_width": "360",
      "ppi": "256",
      "px_density": "150% HDPI",
      "aspect_ratio": "9 : 16"
    },
    "Motorola Droid Razr Maxx": {
      "persist": "Motorola Droid Razr Maxx",
      "operating_system": "Android",
      "physical_size_in": "4.3",
      "physical_size_cm": "11.0",
      "px_width": "540",
      "px_height": "960",
      "device_width": "360",
      "ppi": "256",
      "px_density": "150% HDPI",
      "aspect_ratio": "9 : 16"
    },
    "Motorola Moto X Style": {
      "persist": "Motorola Moto X Style",
      "operating_system": "Android",
      "physical_size_in": "5.7",
      "physical_size_cm": "14.5",
      "px_width": "1440",
      "px_height": "2560",
      "device_width": "-",
      "ppi": "520",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Motorola Nexus 6": {
      "persist": "Motorola Nexus 6",
      "operating_system": "Android",
      "physical_size_in": "6.0",
      "physical_size_cm": "15.2",
      "px_width": "1440",
      "px_height": "2560",
      "device_width": "-",
      "ppi": "493",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Motorola Droid Turbo": {
      "persist": "Motorola Droid Turbo",
      "operating_system": "Android",
      "physical_size_in": "5.2",
      "physical_size_cm": "13.2",
      "px_width": "1440",
      "px_height": "2560",
      "device_width": "-",
      "ppi": "565",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Motorola Moto G": {
      "persist": "Motorola Moto G",
      "operating_system": "Android",
      "physical_size_in": "5.0",
      "physical_size_cm": "12.7",
      "px_width": "720",
      "px_height": "1280",
      "device_width": "-",
      "ppi": "294",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Motorola Moto E": {
      "persist": "Motorola Moto E",
      "operating_system": "Android",
      "physical_size_in": "4.3",
      "physical_size_cm": "10.9",
      "px_width": "540",
      "px_height": "960",
      "device_width": "-",
      "ppi": "256",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "OnePlus X": {
      "persist": "OnePlus X",
      "operating_system": "Android",
      "physical_size_in": "5.0",
      "physical_size_cm": "12.7",
      "px_width": "1080",
      "px_height": "1920",
      "device_width": "-",
      "ppi": "441",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "OnePlus 2": {
      "persist": "OnePlus 2",
      "operating_system": "Android",
      "physical_size_in": "5.5",
      "physical_size_cm": "14.0",
      "px_width": "1080",
      "px_height": "1920",
      "device_width": "-",
      "ppi": "401",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "OnePlus One": {
      "persist": "OnePlus One",
      "operating_system": "Android",
      "physical_size_in": "5.5",
      "physical_size_cm": "14.0",
      "px_width": "1080",
      "px_height": "1920",
      "device_width": "-",
      "ppi": "401",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Samsung Galaxy Nexus": {
      "persist": "Samsung Galaxy Nexus",
      "operating_system": "Android",
      "physical_size_in": "4.6",
      "physical_size_cm": "11.8",
      "px_width": "720",
      "px_height": "1280",
      "device_width": "360",
      "ppi": "316",
      "px_density": "200% XHDPI",
      "aspect_ratio": "9 : 16"
    },
    "Samsung Galaxy Note": {
      "persist": "Samsung Galaxy Note",
      "operating_system": "Android",
      "physical_size_in": "5.3",
      "physical_size_cm": "13.5",
      "px_width": "800",
      "px_height": "1280",
      "device_width": "400",
      "ppi": "285",
      "px_density": "200% XHDPI",
      "aspect_ratio": "5 : 8"
    },
    "Samsung Galaxy Note II": {
      "persist": "Samsung Galaxy Note II",
      "operating_system": "Android",
      "physical_size_in": "5.5",
      "physical_size_cm": "14.1",
      "px_width": "720",
      "px_height": "1280",
      "device_width": "360",
      "ppi": "267",
      "px_density": "200% XHDPI",
      "aspect_ratio": "9 : 16"
    },
    "Samsung Galaxy S": {
      "persist": "Samsung Galaxy S",
      "operating_system": "Android",
      "physical_size_in": "4.0",
      "physical_size_cm": "10.0",
      "px_width": "480",
      "px_height": "800",
      "device_width": "320",
      "ppi": "233",
      "px_density": "150% HDPI",
      "aspect_ratio": "3 : 5"
    },
    "Samsung Galaxy S Plus": {
      "persist": "Samsung Galaxy S Plus",
      "operating_system": "Android",
      "physical_size_in": "4.0",
      "physical_size_cm": "10.0",
      "px_width": "480",
      "px_height": "800",
      "device_width": "320",
      "ppi": "233",
      "px_density": "150% HDPI",
      "aspect_ratio": "3 : 5"
    },
    "Samsung Galaxy SII": {
      "persist": "Samsung Galaxy SII",
      "operating_system": "Android",
      "physical_size_in": "4.3",
      "physical_size_cm": "10.8",
      "px_width": "480",
      "px_height": "800",
      "device_width": "320",
      "ppi": "219",
      "px_density": "150% HDPI",
      "aspect_ratio": "3 : 5"
    },
    "Samsung Galaxy SIII": {
      "persist": "Samsung Galaxy SIII",
      "operating_system": "Android",
      "physical_size_in": "4.8",
      "physical_size_cm": "12.0",
      "px_width": "720",
      "px_height": "1280",
      "device_width": "360",
      "ppi": "306",
      "px_density": "200% XHDPI",
      "aspect_ratio": "9 : 16"
    },
    "Samsung Galaxy SIV": {
      "persist": "Samsung Galaxy SIV",
      "operating_system": "Android",
      "physical_size_in": "5.0",
      "physical_size_cm": "12.7",
      "px_width": "1080",
      "px_height": "1920",
      "device_width": "360",
      "ppi": "441",
      "px_density": "300% XXHDPI",
      "aspect_ratio": "9 : 16"
    },
    "Samsung Nexus S": {
      "persist": "Samsung Nexus S",
      "operating_system": "Android",
      "physical_size_in": "4.0",
      "physical_size_cm": "10.0",
      "px_width": "480",
      "px_height": "800",
      "device_width": "320",
      "ppi": "235",
      "px_density": "150% HDPI",
      "aspect_ratio": "3 : 5"
    },
    "Samsung A5": {
      "persist": "Samsung A5",
      "operating_system": "Android",
      "physical_size_in": "5.0",
      "physical_size_cm": "12.7",
      "px_width": "720",
      "px_height": "1280",
      "device_width": "-",
      "ppi": "294",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Samsung Z3": {
      "persist": "Samsung Z3",
      "operating_system": "Android",
      "physical_size_in": "5.0",
      "physical_size_cm": "12.7",
      "px_width": "720",
      "px_height": "1280",
      "device_width": "-",
      "ppi": "294",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Samsung Galaxy J7": {
      "persist": "Samsung Galaxy J7",
      "operating_system": "Android",
      "physical_size_in": "5.5",
      "physical_size_cm": "14.0",
      "px_width": "720",
      "px_height": "1280",
      "device_width": "-",
      "ppi": "267",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Samsung Galaxy Note5": {
      "persist": "Samsung Galaxy Note5",
      "operating_system": "Android",
      "physical_size_in": "5.7",
      "physical_size_cm": "14.5",
      "px_width": "1440",
      "px_height": "2560",
      "device_width": "-",
      "ppi": "518",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Samsung Galaxy S6 Edge+": {
      "persist": "Samsung Galaxy S6 Edge+",
      "operating_system": "Android",
      "physical_size_in": "5.7",
      "physical_size_cm": "14.5",
      "px_width": "1440",
      "px_height": "2560",
      "device_width": "-",
      "ppi": "518",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Samsung Galaxy S6": {
      "persist": "Samsung Galaxy S6",
      "operating_system": "Android",
      "physical_size_in": "5.1",
      "physical_size_cm": "13.0",
      "px_width": "1440",
      "px_height": "2560",
      "device_width": "-",
      "ppi": "577",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Samsung Galaxy S5": {
      "persist": "Samsung Galaxy S5",
      "operating_system": "Android",
      "physical_size_in": "5.1",
      "physical_size_cm": "13.0",
      "px_width": "1080",
      "px_height": "1920",
      "device_width": "-",
      "ppi": "432",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Samsung Galaxy Note4": {
      "persist": "Samsung Galaxy Note4",
      "operating_system": "Android",
      "physical_size_in": "5.7",
      "physical_size_cm": "14.5",
      "px_width": "1440",
      "px_height": "2560",
      "device_width": "-",
      "ppi": "515",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Sony Xperia Z": {
      "persist": "Sony Xperia Z",
      "operating_system": "Android",
      "physical_size_in": "5.0",
      "physical_size_cm": "12.7",
      "px_width": "1080",
      "px_height": "1920",
      "device_width": "360",
      "ppi": "441",
      "px_density": "300% XXHDPI",
      "aspect_ratio": "9 : 16"
    },
    "Sony Xperia T2 Ultra Dual": {
      "persist": "Sony Xperia T2 Ultra Dual",
      "operating_system": "Android",
      "physical_size_in": "6.0",
      "physical_size_cm": "15.0",
      "px_width": "1280",
      "px_height": "720",
      "device_width": "-",
      "ppi": "245",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Sony Xperia C4 Dual": {
      "persist": "Sony Xperia C4 Dual",
      "operating_system": "Android",
      "physical_size_in": "5.5",
      "physical_size_cm": "14.0",
      "px_width": "1920",
      "px_height": "1080",
      "device_width": "-",
      "ppi": "401",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Sony Xperia Z3": {
      "persist": "Sony Xperia Z3",
      "operating_system": "Android",
      "physical_size_in": "5.2",
      "physical_size_cm": "13.2",
      "px_width": "1080",
      "px_height": "1920",
      "device_width": "-",
      "ppi": "424",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Sony Xperia Z5": {
      "persist": "Sony Xperia Z5",
      "operating_system": "Android",
      "physical_size_in": "5.2",
      "physical_size_cm": "13.2",
      "px_width": "1080",
      "px_height": "1920",
      "device_width": "-",
      "ppi": "424",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Sony Xperia M4": {
      "persist": "Sony Xperia M4",
      "operating_system": "Android",
      "physical_size_in": "5.0",
      "physical_size_cm": "12.7",
      "px_width": "720",
      "px_height": "1280",
      "device_width": "-",
      "ppi": "294",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Xiaomi Mi Note": {
      "persist": "Xiaomi Mi Note",
      "operating_system": "Android",
      "physical_size_in": "5.7",
      "physical_size_cm": "14.5",
      "px_width": "1080",
      "px_height": "1920",
      "device_width": "-",
      "ppi": "386",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Xiaomi Mi 4i": {
      "persist": "Xiaomi Mi 4i",
      "operating_system": "Android",
      "physical_size_in": "5.0",
      "physical_size_cm": "12.7",
      "px_width": "1080",
      "px_height": "1920",
      "device_width": "-",
      "ppi": "441",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Infocus M812i": {
      "persist": "Infocus M812i",
      "operating_system": "Android",
      "physical_size_in": "5.5",
      "physical_size_cm": "14.0",
      "px_width": "1080",
      "px_height": "1920",
      "device_width": "-",
      "ppi": "400",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "VIVO Y51L": {
      "persist": "VIVO Y51L",
      "operating_system": "Android",
      "physical_size_in": "5.0",
      "physical_size_cm": "12.0",
      "px_width": "540",
      "px_height": "960",
      "device_width": "-",
      "ppi": "220",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Oppo A59": {
      "persist": "Oppo A59",
      "operating_system": "Android",
      "physical_size_in": "5.5",
      "physical_size_cm": "14.0",
      "px_width": "1280",
      "px_height": "720",
      "device_width": "-",
      "ppi": "267",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Oppo F1 Plus": {
      "persist": "Oppo F1 Plus",
      "operating_system": "Android",
      "physical_size_in": "5.5",
      "physical_size_cm": "14.0",
      "px_width": "1920",
      "px_height": "1080",
      "device_width": "-",
      "ppi": "401",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Oppo R9 Plus": {
      "persist": "Oppo R9 Plus",
      "operating_system": "Android",
      "physical_size_in": "6.0",
      "physical_size_cm": "15.0",
      "px_width": "1920",
      "px_height": "1080",
      "device_width": "-",
      "ppi": "367",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Oppo R9": {
      "persist": "Oppo R9",
      "operating_system": "Android",
      "physical_size_in": "5.5",
      "physical_size_cm": "14.0",
      "px_width": "1920",
      "px_height": "1080",
      "device_width": "-",
      "ppi": "401",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Oppo A37": {
      "persist": "Oppo A37",
      "operating_system": "Android",
      "physical_size_in": "5.0",
      "physical_size_cm": "12.0",
      "px_width": "1920",
      "px_height": "1080",
      "device_width": "-",
      "ppi": "294",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Oppo F1": {
      "persist": "Oppo F1",
      "operating_system": "Android",
      "physical_size_in": "5.0",
      "physical_size_cm": "12.0",
      "px_width": "1280",
      "px_height": "720",
      "device_width": "-",
      "ppi": "294",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Oppo A53": {
      "persist": "Oppo A53",
      "operating_system": "Android",
      "physical_size_in": "5.5",
      "physical_size_cm": "14.0",
      "px_width": "1280",
      "px_height": "720",
      "device_width": "-",
      "ppi": "267",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Panasonic Eluga I3": {
      "persist": "Panasonic Eluga I3",
      "operating_system": "Android",
      "physical_size_in": "5.5",
      "physical_size_cm": "14.0",
      "px_width": "1280",
      "px_height": "720",
      "device_width": "-",
      "ppi": "267",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Panasonic Eluga Arc": {
      "persist": "Panasonic Eluga Arc",
      "operating_system": "Android",
      "physical_size_in": "4.7",
      "physical_size_cm": "12.0",
      "px_width": "1280",
      "px_height": "720",
      "device_width": "-",
      "ppi": "312",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Panasonic T50": {
      "persist": "Panasonic T50",
      "operating_system": "Android",
      "physical_size_in": "4.5",
      "physical_size_cm": "12.0",
      "px_width": "854",
      "px_height": "480",
      "device_width": "-",
      "ppi": "218",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Philips Sapphire Life V787": {
      "persist": "Philips Sapphire Life V787",
      "operating_system": "Android",
      "physical_size_in": "5.5",
      "physical_size_cm": "14.0",
      "px_width": "1920",
      "px_height": "1080",
      "device_width": "-",
      "ppi": "441",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Philips Sapphire S616": {
      "persist": "Philips Sapphire S616",
      "operating_system": "Android",
      "physical_size_in": "5.5",
      "physical_size_cm": "14.0",
      "px_width": "1920",
      "px_height": "1080",
      "device_width": "-",
      "ppi": "401",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Panasonic T44": {
      "persist": "Panasonic T44",
      "operating_system": "Android",
      "physical_size_in": "4.0",
      "physical_size_cm": "10.0",
      "px_width": "800",
      "px_height": "480",
      "device_width": "-",
      "ppi": "233",
      "px_density": "N/A",
      "aspect_ratio": "5 : 3"
    },
    "Lenovo Vibe K4 Note": {
      "persist": "Lenovo Vibe K4 Note",
      "operating_system": "Android",
      "physical_size_in": "5.5",
      "physical_size_cm": "14.0",
      "px_width": "1920",
      "px_height": "1080",
      "device_width": "-",
      "ppi": "401",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Lenovo K3 Note": {
      "persist": "Lenovo K3 Note",
      "operating_system": "Android",
      "physical_size_in": "5.5",
      "physical_size_cm": "14.0",
      "px_width": "1920",
      "px_height": "1080",
      "device_width": "-",
      "ppi": "401",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Motorola Moto G4 Plus": {
      "persist": "Motorola Moto G4 Plus",
      "operating_system": "Android",
      "physical_size_in": "5.5",
      "physical_size_cm": "14.0",
      "px_width": "1920",
      "px_height": "1080",
      "device_width": "-",
      "ppi": "401",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Motorola Moto Z": {
      "persist": "Motorola Moto Z",
      "operating_system": "Android",
      "physical_size_in": "5.5",
      "physical_size_cm": "14.0",
      "px_width": "2560",
      "px_height": "1440",
      "device_width": "-",
      "ppi": "535",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Sony Xperia X": {
      "persist": "Sony Xperia X",
      "operating_system": "Android",
      "physical_size_in": "5.0",
      "physical_size_cm": "12.0",
      "px_width": "1920",
      "px_height": "1080",
      "device_width": "-",
      "ppi": "441",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Sony Xperia Z1": {
      "persist": "Sony Xperia Z1",
      "operating_system": "Android",
      "physical_size_in": "5.0",
      "physical_size_cm": "12.0",
      "px_width": "1920",
      "px_height": "1080",
      "device_width": "-",
      "ppi": "441",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Sony Xperia E4 Dual": {
      "persist": "Sony Xperia E4 Dual",
      "operating_system": "Android",
      "physical_size_in": "5.0",
      "physical_size_cm": "12.0",
      "px_width": "960",
      "px_height": "540",
      "device_width": "-",
      "ppi": "220",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Xiaomi Redmi Note 3": {
      "persist": "Xiaomi Redmi Note 3",
      "operating_system": "Android",
      "physical_size_in": "5.5",
      "physical_size_cm": "14.0",
      "px_width": "1920",
      "px_height": "1080",
      "device_width": "-",
      "ppi": "403",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Sony Xperia Z3 Plus": {
      "persist": "Sony Xperia Z3 Plus",
      "operating_system": "Android",
      "physical_size_in": "5.2",
      "physical_size_cm": "13.0",
      "px_width": "1920",
      "px_height": "1080",
      "device_width": "-",
      "ppi": "424",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "BlackBerry Q10": {
      "persist": "BlackBerry Q10",
      "operating_system": "BlackBerry",
      "physical_size_in": "3.1",
      "physical_size_cm": "7.9",
      "px_width": "720",
      "px_height": "720",
      "device_width": "360",
      "ppi": "330",
      "px_density": "200% XHDPI",
      "aspect_ratio": "1 : 1"
    },
    "BlackBerry Z10": {
      "persist": "BlackBerry Z10",
      "operating_system": "BlackBerry",
      "physical_size_in": "4.2",
      "physical_size_cm": "10.7",
      "px_width": "768",
      "px_height": "1280",
      "device_width": "384",
      "ppi": "356",
      "px_density": "200% XHDPI",
      "aspect_ratio": "3 : 5"
    },
    "BlackBerry Z3": {
      "persist": "BlackBerry Z3",
      "operating_system": "BlackBerry",
      "physical_size_in": "5.0",
      "physical_size_cm": "12.0",
      "px_width": "960",
      "px_height": "540",
      "device_width": "-",
      "ppi": "220",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "BlackBerry Porsche Design P9982": {
      "persist": "BlackBerry Porsche Design P9982",
      "operating_system": "BlackBerry",
      "physical_size_in": "4.2",
      "physical_size_cm": "10.0",
      "px_width": "1280",
      "px_height": "768",
      "device_width": "-",
      "ppi": "328",
      "px_density": "N/A",
      "aspect_ratio": "1 : 1"
    },
    "BlackBerry Passport": {
      "persist": "BlackBerry Passport",
      "operating_system": "BlackBerry",
      "physical_size_in": "4.5",
      "physical_size_cm": "11.0",
      "px_width": "1440",
      "px_height": "1440",
      "device_width": "-",
      "ppi": "453",
      "px_density": "N/A",
      "aspect_ratio": "1 : 1"
    },
    "BlackBerry Leap": {
      "persist": "BlackBerry Leap",
      "operating_system": "BlackBerry",
      "physical_size_in": "5.0",
      "physical_size_cm": "12.0",
      "px_width": "1280",
      "px_height": "720",
      "device_width": "-",
      "ppi": "294",
      "px_density": "N/A",
      "aspect_ratio": "8 : 5"
    },
    "BlackBerry Classic Non Camera": {
      "persist": "BlackBerry Classic Non Camera",
      "operating_system": "BlackBerry",
      "physical_size_in": "3.5",
      "physical_size_cm": "9.0",
      "px_width": "1280",
      "px_height": "720",
      "device_width": "-",
      "ppi": "291",
      "px_density": "N/A",
      "aspect_ratio": "8 : 5"
    },
    "BlackBerry Torch 9810": {
      "persist": "BlackBerry Torch 9810",
      "operating_system": "BlackBerry",
      "physical_size_in": "3.2",
      "physical_size_cm": "8.0",
      "px_width": "480",
      "px_height": "360",
      "device_width": "-",
      "ppi": "188",
      "px_density": "N/A",
      "aspect_ratio": "4 : 3"
    },
    "Apple iPhone 3GS": {
      "persist": "Apple iPhone 3GS",
      "operating_system": "iOS",
      "physical_size_in": "3.5",
      "physical_size_cm": "8.9",
      "px_width": "320",
      "px_height": "480",
      "device_width": "320",
      "ppi": "163",
      "px_density": "100% MDPI",
      "aspect_ratio": "2 : 3"
    },
    "Apple iPhone 4 (4, 4S)": {
      "persist": "Apple iPhone 4 (4, 4S)",
      "operating_system": "iOS",
      "physical_size_in": "3.5",
      "physical_size_cm": "8.9",
      "px_width": "640",
      "px_height": "960",
      "device_width": "320",
      "ppi": "326",
      "px_density": "200% XHDPI",
      "aspect_ratio": "2 : 3"
    },
    "Apple iPhone 5 (5c, 5s)": {
      "persist": "Apple iPhone 5 (5c, 5s)",
      "operating_system": "iOS",
      "physical_size_in": "4.0",
      "physical_size_cm": "10.0",
      "px_width": "640",
      "px_height": "1136",
      "device_width": "320",
      "ppi": "326",
      "px_density": "200% XHDPI",
      "aspect_ratio": "40 : 71"
    },
    "Apple iPhone 6": {
      "persist": "Apple iPhone 6",
      "operating_system": "iOS",
      "physical_size_in": "4.7",
      "physical_size_cm": "11.9",
      "px_width": "750",
      "px_height": "1334",
      "device_width": "-",
      "ppi": "326",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Apple iPhone 6s": {
      "persist": "Apple iPhone 6s",
      "operating_system": "iOS",
      "physical_size_in": "4.7",
      "physical_size_cm": "11.9",
      "px_width": "750",
      "px_height": "1334",
      "device_width": "-",
      "ppi": "326",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Apple iPhone 6 Plus": {
      "persist": "Apple iPhone 6 Plus",
      "operating_system": "iOS",
      "physical_size_in": "5.5",
      "physical_size_cm": "14.0",
      "px_width": "1080",
      "px_height": "1920",
      "device_width": "-",
      "ppi": "401",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Apple iPhone 7": {
      "persist": "Apple iPhone 7",
      "operating_system": "iOS",
      "physical_size_in": "4.7",
      "physical_size_cm": "11.5",
      "px_width": "750",
      "px_height": "1334",
      "device_width": "-",
      "ppi": "326",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Apple iPhone 7 Plus": {
      "persist": "Apple iPhone 7 Plus",
      "operating_system": "iOS",
      "physical_size_in": "5.5",
      "physical_size_cm": "14.0",
      "px_width": "1080",
      "px_height": "1920",
      "device_width": "-",
      "ppi": "401",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Nokia Lumia (710, 800)": {
      "persist": "Nokia Lumia (710, 800)",
      "operating_system": "Windows",
      "physical_size_in": "3.7",
      "physical_size_cm": "9.4",
      "px_width": "480",
      "px_height": "800",
      "device_width": "320",
      "ppi": "252",
      "px_density": "150% HDPI",
      "aspect_ratio": "3 : 5"
    },
    "Nokia Lumia 1020": {
      "persist": "Nokia Lumia 1020",
      "operating_system": "Windows",
      "physical_size_in": "4.5",
      "physical_size_cm": "11.0",
      "px_width": "768",
      "px_height": "1280",
      "device_width": "384",
      "ppi": "332",
      "px_density": "200% XHDPI",
      "aspect_ratio": "3 : 5"
    },
    "Nokia Lumia 520": {
      "persist": "Nokia Lumia 520",
      "operating_system": "Windows",
      "physical_size_in": "4.0",
      "physical_size_cm": "10.0",
      "px_width": "480",
      "px_height": "800",
      "device_width": "320",
      "ppi": "233",
      "px_density": "150% HDPI",
      "aspect_ratio": "3 : 5"
    },
    "Nokia Lumia 620": {
      "persist": "Nokia Lumia 620",
      "operating_system": "Windows",
      "physical_size_in": "3.8",
      "physical_size_cm": "9.7",
      "px_width": "480",
      "px_height": "800",
      "device_width": "320",
      "ppi": "246",
      "px_density": "150% HDPI",
      "aspect_ratio": "3 : 5"
    },
    "Nokia Lumia 720": {
      "persist": "Nokia Lumia 720",
      "operating_system": "Windows",
      "physical_size_in": "4.3",
      "physical_size_cm": "10.9",
      "px_width": "480",
      "px_height": "800",
      "device_width": "320",
      "ppi": "217",
      "px_density": "150% HDPI",
      "aspect_ratio": "3 : 5"
    },
    "Nokia Lumia 900": {
      "persist": "Nokia Lumia 900",
      "operating_system": "Windows",
      "physical_size_in": "4.3",
      "physical_size_cm": "11.0",
      "px_width": "480",
      "px_height": "800",
      "device_width": "320",
      "ppi": "217",
      "px_density": "150% HDPI",
      "aspect_ratio": "3 : 5"
    },
    "Nokia Lumia 920": {
      "persist": "Nokia Lumia 920",
      "operating_system": "Windows",
      "physical_size_in": "4.5",
      "physical_size_cm": "11.0",
      "px_width": "768",
      "px_height": "1280",
      "device_width": "384",
      "ppi": "332",
      "px_density": "200% XHDPI",
      "aspect_ratio": "3 : 5"
    },
    "Nokia Lumia 925": {
      "persist": "Nokia Lumia 925",
      "operating_system": "Windows",
      "physical_size_in": "4.5",
      "physical_size_cm": "11.0",
      "px_width": "768",
      "px_height": "1280",
      "device_width": "384",
      "ppi": "332",
      "px_density": "200% XHDPI",
      "aspect_ratio": "3 : 5"
    },
    "Microsoft Lumia 950": {
      "persist": "Microsoft Lumia 950",
      "operating_system": "Windows ",
      "physical_size_in": "5.2",
      "physical_size_cm": "13.2",
      "px_width": "1440",
      "px_height": "2560",
      "device_width": "-",
      "ppi": "564",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Panasonic Toughpad FZ-F1": {
      "persist": "Panasonic Toughpad FZ-F1",
      "operating_system": "Windows",
      "physical_size_in": "4.7",
      "physical_size_cm": "12.0",
      "px_width": "1280",
      "px_height": "720",
      "device_width": "-",
      "ppi": "312",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "BlackBerry Priv": {
      "persist": "BlackBerry Priv",
      "operating_system": "Android",
      "physical_size_in": "5.4",
      "physical_size_cm": "13.7",
      "px_width": "1440",
      "px_height": "2560",
      "device_width": "-",
      "ppi": "540",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Huawei Nexus 6p": {
      "persist": "Huawei Nexus 6p",
      "operating_system": "Android",
      "physical_size_in": "5.7",
      "physical_size_cm": "14.5",
      "px_width": "1440",
      "px_height": "2560",
      "device_width": "-",
      "ppi": "518",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Samsung Galaxy SV": {
      "persist": "Samsung Galaxy SV",
      "operating_system": "Android",
      "physical_size_in": "5.1",
      "physical_size_cm": "12.9",
      "px_width": "1080",
      "px_height": "1920",
      "device_width": "-",
      "ppi": "432",
      "px_density": "N/A",
      "aspect_ratio": "9 : 16"
    },
    "Google Pixel": {
      "persist": "Google Pixel",
      "operating_system": "Android",
      "physical_size_in": "5.0",
      "physical_size_cm": "12.7",
      "px_width": "1080",
      "px_height": "1920",
      "device_width": "-",
      "ppi": "441",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Google Pixel XL": {
      "persist": "Google Pixel XL",
      "operating_system": "Android",
      "physical_size_in": "5.5",
      "physical_size_cm": "14.0",
      "px_width": "1440",
      "px_height": "2560",
      "device_width": "-",
      "ppi": "534",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Google Pixel C": {
      "persist": "Google Pixel C",
      "operating_system": "Android",
      "physical_size_in": "10.2",
      "physical_size_cm": "25.0",
      "px_width": "1600",
      "px_height": "1200",
      "device_width": "-",
      "ppi": "307",
      "px_density": "N/A",
      "aspect_ratio": ""
    },
    "Apple iPad Pro 9.7": {
      "persist": "Apple iPad Pro 9.7",
      "operating_system": "iOS",
      "physical_size_in": "9.4",
      "physical_size_cm": "24.0",
      "px_width": "2048",
      "px_height": "1536",
      "device_width": "-",
      "ppi": "264",
      "px_density": "N/A",
      "aspect_ratio": "4 : 3"
    },
    "Acer Iconia Tab A": {
      "persist": "Acer Iconia Tab A",
      "operating_system": "Android",
      "physical_size_in": "10.1",
      "physical_size_cm": "26.0",
      "px_width": "1280",
      "px_height": "800",
      "device_width": "-",
      "ppi": "149",
      "px_density": "N/A",
      "aspect_ratio": "16 : 10"
    },
    "Acer Picasso": {
      "persist": "Acer Picasso",
      "operating_system": "Android",
      "physical_size_in": "11.6",
      "physical_size_cm": "29.0",
      "px_width": "768",
      "px_height": "1280",
      "device_width": "768",
      "ppi": "190",
      "px_density": "100% MDPI",
      "aspect_ratio": "3 : 5"
    },
    "Ainol Novo 10 Hero": {
      "persist": "Ainol Novo 10 Hero",
      "operating_system": "Android",
      "physical_size_in": "10.0",
      "physical_size_cm": "25.0",
      "px_width": "1280",
      "px_height": "800",
      "device_width": "1280",
      "ppi": "149",
      "px_density": "100% MDPI",
      "aspect_ratio": "8 : 5"
    },
    "Ainol Novo 7 Crystal": {
      "persist": "Ainol Novo 7 Crystal",
      "operating_system": "Android",
      "physical_size_in": "7.0",
      "physical_size_cm": "18.0",
      "px_width": "1024",
      "px_height": "600",
      "device_width": "1024",
      "ppi": "169",
      "px_density": "100% MDPI",
      "aspect_ratio": "16 : 9"
    },
    "Ainol Novo 9 Spark": {
      "persist": "Ainol Novo 9 Spark",
      "operating_system": "Android",
      "physical_size_in": "9.7",
      "physical_size_cm": "25.0",
      "px_width": "2048",
      "px_height": "1536",
      "device_width": "1024",
      "ppi": "264",
      "px_density": "200% XHDPI",
      "aspect_ratio": "4 : 3"
    },
    "Amazon Kindle Fire 1st Gen": {
      "persist": "Amazon Kindle Fire 1st Gen",
      "operating_system": "Android",
      "physical_size_in": "7.0",
      "physical_size_cm": "18.0",
      "px_width": "600",
      "px_height": "1024",
      "device_width": "600",
      "ppi": "169",
      "px_density": "100% MDPI",
      "aspect_ratio": "75 : 128"
    },
    "Amazon Kindle Fire HD 7&quot;": {
      "persist": "Amazon Kindle Fire HD 7&quot;",
      "operating_system": "Android",
      "physical_size_in": "7.0",
      "physical_size_cm": "18.0",
      "px_width": "800",
      "px_height": "1280",
      "device_width": "533",
      "ppi": "216",
      "px_density": "150% HDPI",
      "aspect_ratio": "5 : 8"
    },
    "Amazon Kindle Fire HD 8.9&quot;": {
      "persist": "Amazon Kindle Fire HD 8.9&quot;",
      "operating_system": "Android",
      "physical_size_in": "8.9",
      "physical_size_cm": "23.0",
      "px_width": "1200",
      "px_height": "1920",
      "device_width": "800",
      "ppi": "254",
      "px_density": "150% HDPI",
      "aspect_ratio": "5 : 8"
    },
    "Asus Zenfone 2 Laser ZE500KL": {
      "persist": "Asus Zenfone 2 Laser ZE500KL",
      "operating_system": "Android",
      "physical_size_in": "5.0",
      "physical_size_cm": "13.0",
      "px_width": "1280",
      "px_height": "720",
      "device_width": "-",
      "ppi": "294",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Asus Nexus 7 (2012)": {
      "persist": "Asus Nexus 7 (2012)",
      "operating_system": "Android",
      "physical_size_in": "7.0",
      "physical_size_cm": "18.0",
      "px_width": "800",
      "px_height": "1280",
      "device_width": "603",
      "ppi": "216",
      "px_density": "N/A",
      "aspect_ratio": "5 : 8"
    },
    "Asus Nexus 7 (2013)": {
      "persist": "Asus Nexus 7 (2013)",
      "operating_system": "Android",
      "physical_size_in": "7.0",
      "physical_size_cm": "18.0",
      "px_width": "1200",
      "px_height": "1920",
      "device_width": "600",
      "ppi": "323",
      "px_density": "200% XHDPI",
      "aspect_ratio": " N/A "
    },
    "Asus Transformer": {
      "persist": "Asus Transformer",
      "operating_system": "Android",
      "physical_size_in": "10.1",
      "physical_size_cm": "26.0",
      "px_width": "1200",
      "px_height": "1920",
      "device_width": "800",
      "ppi": "224",
      "px_density": "150% HDPI",
      "aspect_ratio": "5 : 8"
    },
    "Barnes &amp; Noble Nook Tablet": {
      "persist": "Barnes &amp; Noble Nook Tablet",
      "operating_system": "Android",
      "physical_size_in": "7.0",
      "physical_size_cm": "18.0",
      "px_width": "600",
      "px_height": "800",
      "device_width": "600",
      "ppi": "171",
      "px_density": "100% MDPI",
      "aspect_ratio": "3 : 4"
    },
    "HP 7 Voice Tab": {
      "persist": "HP 7 Voice Tab",
      "operating_system": "Android",
      "physical_size_in": "6.9",
      "physical_size_cm": "24.0",
      "px_width": "1024",
      "px_height": "600",
      "device_width": "-",
      "ppi": "216",
      "px_density": "N/A",
      "aspect_ratio": "16 : 10"
    },
    "HP 7 Plus Tablet": {
      "persist": "HP 7 Plus Tablet",
      "operating_system": "Android",
      "physical_size_in": "7.0",
      "physical_size_cm": "17.7",
      "px_width": "1024",
      "px_height": "600",
      "device_width": "-",
      "ppi": "170",
      "px_density": "N/A",
      "aspect_ratio": "16 : 10"
    },
    "HP Slate 8 Plus Tablet": {
      "persist": "HP Slate 8 Plus Tablet",
      "operating_system": "Android",
      "physical_size_in": "8.0",
      "physical_size_cm": "20.3",
      "px_width": "1280",
      "px_height": "800",
      "device_width": "-",
      "ppi": "216",
      "px_density": "N/A",
      "aspect_ratio": "16 : 10"
    },
    "Huawei Mat": {
      "persist": "Huawei Mat",
      "operating_system": "Android",
      "physical_size_in": "6.0",
      "physical_size_cm": "15.2",
      "px_width": "1920",
      "px_height": "1080",
      "device_width": "-",
      "ppi": "368",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Micromax Canvas 6": {
      "persist": "Micromax Canvas 6",
      "operating_system": "Android",
      "physical_size_in": "5.5",
      "physical_size_cm": "13.9",
      "px_width": "1920",
      "px_height": "1080",
      "device_width": "-",
      "ppi": "401",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Samsung Galaxy Tab 10.1": {
      "persist": "Samsung Galaxy Tab 10.1",
      "operating_system": "Android",
      "physical_size_in": "10.1",
      "physical_size_cm": "25.7",
      "px_width": "800",
      "px_height": "1280",
      "device_width": "533",
      "ppi": "149",
      "px_density": "150% HDPI",
      "aspect_ratio": "5 : 8"
    },
    "Samsung Galaxy Tab 2": {
      "persist": "Samsung Galaxy Tab 2",
      "operating_system": "Android",
      "physical_size_in": "7.7",
      "physical_size_cm": "20.0",
      "px_width": "600",
      "px_height": "1024",
      "device_width": "600",
      "ppi": "170",
      "px_density": "100% MDPI",
      "aspect_ratio": "75 : 128"
    },
    "Samsung Galaxy Tab 2 10.1": {
      "persist": "Samsung Galaxy Tab 2 10.1",
      "operating_system": "Android",
      "physical_size_in": "10.1",
      "physical_size_cm": "26.0",
      "px_width": "800",
      "px_height": "1280",
      "device_width": "800",
      "ppi": "149",
      "px_density": "100% MDPI",
      "aspect_ratio": "5 : 8"
    },
    "Samsung Galaxy Tab S2": {
      "persist": "Samsung Galaxy Tab S2",
      "operating_system": "Android",
      "physical_size_in": "9.7",
      "physical_size_cm": "24.4",
      "px_width": "2048",
      "px_height": "1536",
      "device_width": "-",
      "ppi": "264",
      "px_density": "N/A",
      "aspect_ratio": "4 : 3"
    },
    "Toshiba AT100": {
      "persist": "Toshiba AT100",
      "operating_system": "Android",
      "physical_size_in": "10.1",
      "physical_size_cm": "25.7",
      "px_width": "1280",
      "px_height": "800",
      "device_width": "853",
      "ppi": "149",
      "px_density": "150% HDPI",
      "aspect_ratio": "8 : 5"
    },
    "Toshiba Thrive": {
      "persist": "Toshiba Thrive",
      "operating_system": "Android",
      "physical_size_in": "10.1",
      "physical_size_cm": "25.6",
      "px_width": "800",
      "px_height": "1280",
      "device_width": "-",
      "ppi": "149",
      "px_density": "N/A",
      "aspect_ratio": "16 : 10"
    },
    "Toshiba Encore 2 Write": {
      "persist": "Toshiba Encore 2 Write",
      "operating_system": "Android",
      "physical_size_in": "10.1",
      "physical_size_cm": "25.7",
      "px_width": "1280",
      "px_height": "800",
      "device_width": "-",
      "ppi": "189",
      "px_density": "N/A",
      "aspect_ratio": "5 : 8"
    },
    "Dell Venue": {
      "persist": "Dell Venue",
      "operating_system": "Android",
      "physical_size_in": "7.0",
      "physical_size_cm": "17.7",
      "px_width": "1280",
      "px_height": "800",
      "device_width": "-",
      "ppi": "216",
      "px_density": "N/A",
      "aspect_ratio": "5 : 3"
    },
    "HP Slate 7": {
      "persist": "HP Slate 7",
      "operating_system": "Android",
      "physical_size_in": "7.0",
      "physical_size_cm": "17.7",
      "px_width": "1024",
      "px_height": "600",
      "device_width": "-",
      "ppi": "170",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Toshiba Excite Go": {
      "persist": "Toshiba Excite Go",
      "operating_system": "Android",
      "physical_size_in": "7.0",
      "physical_size_cm": "17.7",
      "px_width": "1024",
      "px_height": "600",
      "device_width": "-",
      "ppi": "170",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "HP Slate7 VoiceTab": {
      "persist": "HP Slate7 VoiceTab",
      "operating_system": "Android",
      "physical_size_in": "7.0",
      "physical_size_cm": "17.7",
      "px_width": "1280",
      "px_height": "800",
      "device_width": "-",
      "ppi": "294",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "BlackBerry PlayBook": {
      "persist": "BlackBerry PlayBook",
      "operating_system": "BlackBerry",
      "physical_size_in": "7.0",
      "physical_size_cm": "18.0",
      "px_width": "1024",
      "px_height": "600",
      "device_width": "1024",
      "ppi": "169",
      "px_density": "100% MDPI",
      "aspect_ratio": "16 : 9"
    },
    "Apple iPad 1 &amp; 2": {
      "persist": "Apple iPad 1 &amp; 2",
      "operating_system": "iOS",
      "physical_size_in": "9.7",
      "physical_size_cm": "25.0",
      "px_width": "768",
      "px_height": "1024",
      "device_width": "768",
      "ppi": "132",
      "px_density": "100% MDPI",
      "aspect_ratio": "3 : 4"
    },
    "Apple iPad 3 &amp; 4": {
      "persist": "Apple iPad 3 &amp; 4",
      "operating_system": "iOS",
      "physical_size_in": "9.7",
      "physical_size_cm": "25.0",
      "px_width": "1536",
      "px_height": "2048",
      "device_width": "768",
      "ppi": "264",
      "px_density": "200% XHDPI",
      "aspect_ratio": "3 : 4"
    },
    "Apple iPad Mini": {
      "persist": "Apple iPad Mini",
      "operating_system": "iOS",
      "physical_size_in": "7.9",
      "physical_size_cm": "20.0",
      "px_width": "768",
      "px_height": "1024",
      "device_width": "768",
      "ppi": "163",
      "px_density": "100% MDPI",
      "aspect_ratio": "3 : 4"
    },
    "FUJITSU LIFEBOOK U745": {
      "persist": "FUJITSU LIFEBOOK U745",
      "operating_system": "Windows",
      "physical_size_in": "14.0",
      "physical_size_cm": "35.6",
      "px_width": "900",
      "px_height": "1600",
      "device_width": "-",
      "ppi": "300",
      "px_density": "N/A",
      "aspect_ratio": "5 : 8"
    },
    "FUJITSU STYLISTIC Q665": {
      "persist": "FUJITSU STYLISTIC Q665",
      "operating_system": "Windows",
      "physical_size_in": "11.6",
      "physical_size_cm": "29.5",
      "px_width": "1080",
      "px_height": "1920",
      "device_width": "720",
      "ppi": "190",
      "px_density": "150% HDPI",
      "aspect_ratio": "16 : 9"
    },
    "FUJITSU STYLISTIC Q736": {
      "persist": "FUJITSU STYLISTIC Q736",
      "operating_system": "Windows",
      "physical_size_in": "13.3",
      "physical_size_cm": "33.8",
      "px_width": "1080",
      "px_height": "1920",
      "device_width": "540",
      "ppi": "165",
      "px_density": "200% XHDPI",
      "aspect_ratio": "16 : 9"
    },
    "Acer Switch 10 E": {
      "persist": "Acer Switch 10 E",
      "operating_system": "Android",
      "physical_size_in": "10.1",
      "physical_size_cm": "25.7",
      "px_width": "1280",
      "px_height": "800",
      "device_width": "-",
      "ppi": "154",
      "px_density": "N/A",
      "aspect_ratio": "5 : 8"
    },
    "Amazon Fire HD 8": {
      "persist": "Amazon Fire HD 8",
      "operating_system": "Android",
      "physical_size_in": "8.0",
      "physical_size_cm": "20.3",
      "px_width": "1280",
      "px_height": "800",
      "device_width": "-",
      "ppi": "189",
      "px_density": "N/A",
      "aspect_ratio": "5 : 8"
    },
    "Amazon Fire": {
      "persist": "Amazon Fire",
      "operating_system": "Android",
      "physical_size_in": "7.0",
      "physical_size_cm": "17.8",
      "px_width": "1024",
      "px_height": "600",
      "device_width": "-",
      "ppi": "171",
      "px_density": "N/A",
      "aspect_ratio": "5 : 8"
    },
    "Amazon Fire HD 6": {
      "persist": "Amazon Fire HD 6",
      "operating_system": "Android",
      "physical_size_in": "6.0",
      "physical_size_cm": "15.2",
      "px_width": "1280",
      "px_height": "800",
      "device_width": "-",
      "ppi": "252",
      "px_density": "N/A",
      "aspect_ratio": "5 : 8"
    },
    "Amazon Fire HD 10": {
      "persist": "Amazon Fire HD 10",
      "operating_system": "Android",
      "physical_size_in": "10.0",
      "physical_size_cm": "25.4",
      "px_width": "1280",
      "px_height": "800",
      "device_width": "-",
      "ppi": "149",
      "px_density": "N/A",
      "aspect_ratio": "5 : 8"
    },
    "Asus ZenPad 8.0": {
      "persist": "Asus ZenPad 8.0",
      "operating_system": "Android",
      "physical_size_in": "8.0",
      "physical_size_cm": "20.3",
      "px_width": "1280",
      "px_height": "800",
      "device_width": "-",
      "ppi": "189",
      "px_density": "N/A",
      "aspect_ratio": "5 : 8"
    },
    "Asus Nexus 7": {
      "persist": "Asus Nexus 7",
      "operating_system": "Android",
      "physical_size_in": "7.0",
      "physical_size_cm": "17.8",
      "px_width": "1280",
      "px_height": "800",
      "device_width": "-",
      "ppi": "323",
      "px_density": "N/A",
      "aspect_ratio": "5 : 8"
    },
    "HTC Nexus 9": {
      "persist": "HTC Nexus 9",
      "operating_system": "Android",
      "physical_size_in": "8.9",
      "physical_size_cm": "22.6",
      "px_width": "2048",
      "px_height": "1536",
      "device_width": "-",
      "ppi": "288",
      "px_density": "N/A",
      "aspect_ratio": "3 : 4"
    },
    "Lenovo Yoga 10 HD+": {
      "persist": "Lenovo Yoga 10 HD+",
      "operating_system": "Android",
      "physical_size_in": "10.1",
      "physical_size_cm": "25.7",
      "px_width": "1920",
      "px_height": "1200",
      "device_width": "-",
      "ppi": "224",
      "px_density": "N/A",
      "aspect_ratio": "5 : 8"
    },
    "Samsung Galaxy Note Pro 12.2": {
      "persist": "Samsung Galaxy Note Pro 12.2",
      "operating_system": "Android",
      "physical_size_in": "12.2",
      "physical_size_cm": "31.0",
      "px_width": "2560",
      "px_height": "1600",
      "device_width": "-",
      "ppi": "247",
      "px_density": "N/A",
      "aspect_ratio": "5 : 8"
    },
    "Sony Xperia Z4": {
      "persist": "Sony Xperia Z4",
      "operating_system": "Android",
      "physical_size_in": "10.1",
      "physical_size_cm": "25.7",
      "px_width": "2560",
      "px_height": "1600",
      "device_width": "-",
      "ppi": "299",
      "px_density": "N/A",
      "aspect_ratio": "5 : 8"
    },
    "Apple iPad Air": {
      "persist": "Apple iPad Air",
      "operating_system": "iOS",
      "physical_size_in": "9.7",
      "physical_size_cm": "24.4",
      "px_width": "2048",
      "px_height": "1536",
      "device_width": "-",
      "ppi": "264",
      "px_density": "N/A",
      "aspect_ratio": "3 : 4"
    },
    "Apple iPad Air 2": {
      "persist": "Apple iPad Air 2",
      "operating_system": "iOS",
      "physical_size_in": "9.7",
      "physical_size_cm": "24.4",
      "px_width": "2048",
      "px_height": "1536",
      "device_width": "-",
      "ppi": "264",
      "px_density": "N/A",
      "aspect_ratio": "3 : 4"
    },
    "Apple iPad Pro": {
      "persist": "Apple iPad Pro",
      "operating_system": "iOS",
      "physical_size_in": "12.9",
      "physical_size_cm": "32.8",
      "px_width": "2732",
      "px_height": "2048",
      "device_width": "-",
      "ppi": "264",
      "px_density": "N/A",
      "aspect_ratio": "3 : 4"
    },
    "Apple iPad Mini 2": {
      "persist": "Apple iPad Mini 2",
      "operating_system": "iOS",
      "physical_size_in": "7.9",
      "physical_size_cm": "20.1",
      "px_width": "2048",
      "px_height": "1536",
      "device_width": "-",
      "ppi": "264",
      "px_density": "N/A",
      "aspect_ratio": "3 : 4"
    },
    "Apple iPad Mini 4": {
      "persist": "Apple iPad Mini 4",
      "operating_system": "iOS",
      "physical_size_in": "7.9",
      "physical_size_cm": "20.1",
      "px_width": "2048",
      "px_height": "1536",
      "device_width": "-",
      "ppi": "264",
      "px_density": "N/A",
      "aspect_ratio": "3 : 4"
    },
    "Dell Venue 10": {
      "persist": "Dell Venue 10",
      "operating_system": "Windows",
      "physical_size_in": "10.1",
      "physical_size_cm": "25.7",
      "px_width": "1280",
      "px_height": "800",
      "device_width": "-",
      "ppi": "149",
      "px_density": "N/A",
      "aspect_ratio": "5 : 8"
    },
    "Microsoft Surface Pro 3": {
      "persist": "Microsoft Surface Pro 3",
      "operating_system": "Windows",
      "physical_size_in": "12.0",
      "physical_size_cm": "30.5",
      "px_width": "2160",
      "px_height": "1440",
      "device_width": "-",
      "ppi": "216",
      "px_density": "N/A",
      "aspect_ratio": "2 : 3"
    },
    "Microsoft Surface Pro 4": {
      "persist": "Microsoft Surface Pro 4",
      "operating_system": "Windows",
      "physical_size_in": "12.3",
      "physical_size_cm": "31.2",
      "px_width": "2736",
      "px_height": "1824",
      "device_width": "-",
      "ppi": "267",
      "px_density": "N/A",
      "aspect_ratio": "2 : 3"
    },
    "Microsoft Surface Pro (2017)": {
      "persist": "Microsoft Surface Pro (2017)",
      "operating_system": "Windows",
      "physical_size_in": "12.5",
      "physical_size_cm": "31.8",
      "px_width": "2736",
      "px_height": "1824",
      "device_width": "-",
      "ppi": "263",
      "px_density": "N/A",
      "aspect_ratio": "3 : 2"
    },
    "Samsung Galaxy S8": {
      "persist": "Samsung Galaxy S8",
      "operating_system": "Android",
      "physical_size_in": "5.8",
      "physical_size_cm": "14.7",
      "px_width": "2960",
      "px_height": "1440",
      "device_width": "-",
      "ppi": "570",
      "px_density": "N/A",
      "aspect_ratio": "5 : 9"
    },
    "Samsung Galaxy S8+": {
      "persist": "Samsung Galaxy S8+",
      "operating_system": "Android",
      "physical_size_in": "6.2",
      "physical_size_cm": "15.7",
      "px_width": "2960",
      "px_height": "1440",
      "device_width": "-",
      "ppi": "529",
      "px_density": "N/A",
      "aspect_ratio": "5 : 9"
    },
    "Apple iPhone 8": {
      "persist": "Apple iPhone 8",
      "operating_system": "iOS",
      "physical_size_in": "4.7",
      "physical_size_cm": "11.9",
      "px_width": "750",
      "px_height": "1334",
      "device_width": "-",
      "ppi": "326",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Apple iPhone 8 Plus": {
      "persist": "Apple iPhone 8 Plus",
      "operating_system": "iOS",
      "physical_size_in": "5.5",
      "physical_size_cm": "13.9",
      "px_width": "1080",
      "px_height": "1920",
      "device_width": "-",
      "ppi": "401",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Apple iPhone X": {
      "persist": "Apple iPhone X",
      "operating_system": "iOS",
      "physical_size_in": "5.8",
      "physical_size_cm": "14.7",
      "px_width": "1125",
      "px_height": "2436",
      "device_width": "-",
      "ppi": "463",
      "px_density": "N/A",
      "aspect_ratio": "19 . 5 : 9"
    },
    "Apple iPhone XR": {
      "persist": "Apple iPhone XR",
      "operating_system": "iOS",
      "physical_size_in": "6.1",
      "physical_size_cm": "15.5",
      "px_width": "828",
      "px_height": "1792",
      "device_width": "-",
      "ppi": "326",
      "px_density": "N/A",
      "aspect_ratio": "19 . 5 : 9"
    },
    "Apple iPhone XS": {
      "persist": "Apple iPhone XS",
      "operating_system": "iOS",
      "physical_size_in": "5.8",
      "physical_size_cm": "14.7",
      "px_width": "1125",
      "px_height": "2436",
      "device_width": "-",
      "ppi": "458",
      "px_density": "N/A",
      "aspect_ratio": "19 . 5 : 9"
    },
    "Apple iPhone XS Max": {
      "persist": "Apple iPhone XS Max",
      "operating_system": "iOS",
      "physical_size_in": "6.5",
      "physical_size_cm": "16.5",
      "px_width": "1242",
      "px_height": "2688",
      "device_width": "-",
      "ppi": "458",
      "px_density": "N/A",
      "aspect_ratio": "19 . 5 : 9"
    },
    "Apple iPhone SE": {
      "persist": "Apple iPhone SE",
      "operating_system": "iOS",
      "physical_size_in": "4.0",
      "physical_size_cm": "10.0",
      "px_width": "640",
      "px_height": "1136",
      "device_width": "-",
      "ppi": "326",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Apple iPhone 11": {
      "persist": "Apple iPhone 11",
      "operating_system": "iOS",
      "physical_size_in": "6.1",
      "physical_size_cm": "15.5",
      "px_width": "828",
      "px_height": "1792",
      "device_width": "-",
      "ppi": "326",
      "px_density": "N/A",
      "aspect_ratio": "19 . 5 : 9"
    },
    "Apple iPhone 11 Pro": {
      "persist": "Apple iPhone 11 Pro",
      "operating_system": "iOS",
      "physical_size_in": "5.8",
      "physical_size_cm": "14.7",
      "px_width": "1125",
      "px_height": "2436",
      "device_width": "-",
      "ppi": "458",
      "px_density": "N/A",
      "aspect_ratio": "19 . 5 : 9"
    },
    "Apple iPhone 11 Pro Max": {
      "persist": "Apple iPhone 11 Pro Max",
      "operating_system": "iOS",
      "physical_size_in": "6.5",
      "physical_size_cm": "16.5",
      "px_width": "1242",
      "px_height": "2688",
      "device_width": "-",
      "ppi": "458",
      "px_density": "N/A",
      "aspect_ratio": "19 . 5 : 9"
    },
    "Samsung Galaxy S10 Lite": {
      "persist": "Samsung Galaxy S10 Lite",
      "operating_system": "Android",
      "physical_size_in": "6.7",
      "physical_size_cm": "17.0",
      "px_width": "1080",
      "px_height": "2400",
      "device_width": "-",
      "ppi": "394",
      "px_density": "N/A",
      "aspect_ratio": "20 : 9"
    },
    "Huawei Nova 7i": {
      "persist": "Huawei Nova 7i",
      "operating_system": "Android",
      "physical_size_in": "6.4",
      "physical_size_cm": "16.3",
      "px_width": "1080",
      "px_height": "2310",
      "device_width": "-",
      "ppi": "398",
      "px_density": "N/A",
      "aspect_ratio": "19 . 2 : 9"
    },
    "Xiaomi Redmi Note 9 Pro": {
      "persist": "Xiaomi Redmi Note 9 Pro",
      "operating_system": "Android",
      "physical_size_in": "6.7",
      "physical_size_cm": "17.0",
      "px_width": "1080",
      "px_height": "2400",
      "device_width": "-",
      "ppi": "395",
      "px_density": "N/A",
      "aspect_ratio": "20 : 9"
    },
    "Xiaomi Redmi Note 9": {
      "persist": "Xiaomi Redmi Note 9",
      "operating_system": "Android",
      "physical_size_in": "6.5",
      "physical_size_cm": "16.5",
      "px_width": "1080",
      "px_height": "2340",
      "device_width": "-",
      "ppi": "395",
      "px_density": "N/A",
      "aspect_ratio": "19 . 5 : 9"
    },
    "Xiaomi Redmi Note 8 Pro": {
      "persist": "Xiaomi Redmi Note 8 Pro",
      "operating_system": "Android",
      "physical_size_in": "6.5",
      "physical_size_cm": "16.5",
      "px_width": "1080",
      "px_height": "2340",
      "device_width": "-",
      "ppi": "395",
      "px_density": "N/A",
      "aspect_ratio": "19 . 5 : 9"
    },
    "Xiaomi Redmi Note 8": {
      "persist": "Xiaomi Redmi Note 8",
      "operating_system": "Android",
      "physical_size_in": "6.3",
      "physical_size_cm": "16.0",
      "px_width": "1080",
      "px_height": "2340",
      "device_width": "-",
      "ppi": "409",
      "px_density": "N/A",
      "aspect_ratio": "19 . 5 : 9"
    },
    "Xiaomi Redmi Note 7": {
      "persist": "Xiaomi Redmi Note 7",
      "operating_system": "Android",
      "physical_size_in": "6.3",
      "physical_size_cm": "16.0",
      "px_width": "1080",
      "px_height": "2340",
      "device_width": "-",
      "ppi": "409",
      "px_density": "N/A",
      "aspect_ratio": "19 . 5 : 9"
    },
    "Samsung Galaxy A71": {
      "persist": "Samsung Galaxy A71",
      "operating_system": "Android",
      "physical_size_in": "6.7",
      "physical_size_cm": "17.0",
      "px_width": "1080",
      "px_height": "2400",
      "device_width": "-",
      "ppi": "393",
      "px_density": "N/A",
      "aspect_ratio": "20 : 9"
    },
    "Samsung Galaxy A51": {
      "persist": "Samsung Galaxy A51",
      "operating_system": "Android",
      "physical_size_in": "6.5",
      "physical_size_cm": "16.5",
      "px_width": "1080",
      "px_height": "2400",
      "device_width": "-",
      "ppi": "405",
      "px_density": "N/A",
      "aspect_ratio": "20 : 9"
    },
    "Samsung Galaxy A70s": {
      "persist": "Samsung Galaxy A70s",
      "operating_system": "Android",
      "physical_size_in": "6.4",
      "physical_size_cm": "16.3",
      "px_width": "1080",
      "px_height": "2400",
      "device_width": "-",
      "ppi": "403",
      "px_density": "N/A",
      "aspect_ratio": "20 : 9"
    },
    "Samsung Galaxy A30": {
      "persist": "Samsung Galaxy A30",
      "operating_system": "Android",
      "physical_size_in": "6.4",
      "physical_size_cm": "16.3",
      "px_width": "1080",
      "px_height": "2340",
      "device_width": "-",
      "ppi": "403",
      "px_density": "N/A",
      "aspect_ratio": "19 . 5 : 9"
    },
    "Samsung Galaxy A50s": {
      "persist": "Samsung Galaxy A50s",
      "operating_system": "Android",
      "physical_size_in": "6.4",
      "physical_size_cm": "16.3",
      "px_width": "1080",
      "px_height": "2340",
      "device_width": "-",
      "ppi": "403",
      "px_density": "N/A",
      "aspect_ratio": "19 . 5 : 9"
    },
    "Samsung Galaxy A50": {
      "persist": "Samsung Galaxy A50",
      "operating_system": "Android",
      "physical_size_in": "6.4",
      "physical_size_cm": "16.3",
      "px_width": "1080",
      "px_height": "2340",
      "device_width": "-",
      "ppi": "403",
      "px_density": "N/A",
      "aspect_ratio": "19 . 5 : 9"
    },
    "Samsung Galaxy S7": {
      "persist": "Samsung Galaxy S7",
      "operating_system": "Android",
      "physical_size_in": "5.1",
      "physical_size_cm": "12.9",
      "px_width": "1440",
      "px_height": "2560",
      "device_width": "-",
      "ppi": "577",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Samsung Galaxy S7 Edge": {
      "persist": "Samsung Galaxy S7 Edge",
      "operating_system": "Android",
      "physical_size_in": "5.5",
      "physical_size_cm": "13.9",
      "px_width": "1440",
      "px_height": "2560",
      "device_width": "-",
      "ppi": "534",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Samsung Galaxy S20+": {
      "persist": "Samsung Galaxy S20+",
      "operating_system": "Android",
      "physical_size_in": "6.7",
      "physical_size_cm": "17.0",
      "px_width": "1440",
      "px_height": "3200",
      "device_width": "-",
      "ppi": "525",
      "px_density": "N/A",
      "aspect_ratio": "20 : 9"
    },
    "Samsung Galaxy Note20 Ultra": {
      "persist": "Samsung Galaxy Note20 Ultra",
      "operating_system": "Android",
      "physical_size_in": "6.9",
      "physical_size_cm": "17.5",
      "px_width": "1440",
      "px_height": "3088",
      "device_width": "-",
      "ppi": "496",
      "px_density": "N/A",
      "aspect_ratio": "19 . 3 : 9"
    },
    "Samsung Galaxy S20 Ultra": {
      "persist": "Samsung Galaxy S20 Ultra",
      "operating_system": "Android",
      "physical_size_in": "6.9",
      "physical_size_cm": "17.5",
      "px_width": "1440",
      "px_height": "3200",
      "device_width": "-",
      "ppi": "511",
      "px_density": "N/A",
      "aspect_ratio": "20 : 9"
    },
    "Samsung Galaxy Note10+": {
      "persist": "Samsung Galaxy Note10+",
      "operating_system": "Android",
      "physical_size_in": "6.8",
      "physical_size_cm": "17.3",
      "px_width": "1440",
      "px_height": "3040",
      "device_width": "-",
      "ppi": "498",
      "px_density": "N/A",
      "aspect_ratio": "19 : 9"
    },
    "Samsung Galaxy Note20": {
      "persist": "Samsung Galaxy Note20",
      "operating_system": "Android",
      "physical_size_in": "6.7",
      "physical_size_cm": "17.0",
      "px_width": "1080",
      "px_height": "2400",
      "device_width": "-",
      "ppi": "393",
      "px_density": "N/A",
      "aspect_ratio": "20 : 9"
    },
    "Samsung Galaxy Note10": {
      "persist": "Samsung Galaxy Note10",
      "operating_system": "Android",
      "physical_size_in": "6.3",
      "physical_size_cm": "16.0",
      "px_width": "1080",
      "px_height": "2280",
      "device_width": "-",
      "ppi": "401",
      "px_density": "N/A",
      "aspect_ratio": "19 : 9"
    },
    "Samsung Galaxy J7 Prime2": {
      "persist": "Samsung Galaxy J7 Prime2",
      "operating_system": "Android",
      "physical_size_in": "5.5",
      "physical_size_cm": "13.9",
      "px_width": "1080",
      "px_height": "1920",
      "device_width": "-",
      "ppi": "401",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Samsung Galaxy J7 Prime": {
      "persist": "Samsung Galaxy J7 Prime",
      "operating_system": "Android",
      "physical_size_in": "5.5",
      "physical_size_cm": "13.9",
      "px_width": "1080",
      "px_height": "1920",
      "device_width": "-",
      "ppi": "401",
      "px_density": "N/A",
      "aspect_ratio": "17 : 9"
    },
    "Apple iPad Air (2019)": {
      "persist": "Apple iPad Air (2019)",
      "operating_system": "iOS",
      "physical_size_in": "10.5",
      "physical_size_cm": "26.7",
      "px_width": "2224",
      "px_height": "1668",
      "device_width": "-",
      "ppi": "264",
      "px_density": "N/A",
      "aspect_ratio": "3 : 4"
    },
    "Apple iPad Air (2020)": {
      "persist": "Apple iPad Air (2020)",
      "operating_system": "iOS",
      "physical_size_in": "10.9",
      "physical_size_cm": "27.7",
      "px_width": "2360",
      "px_height": "1640",
      "device_width": "-",
      "ppi": "264",
      "px_density": "N/A",
      "aspect_ratio": "16 : 23"
    },
    "Apple iPhone SE 2020": {
      "persist": "Apple iPhone SE 2020",
      "operating_system": "iOS",
      "physical_size_in": "4.7",
      "physical_size_cm": "11.9",
      "px_width": "750",
      "px_height": "1334",
      "device_width": "-",
      "ppi": "326",
      "px_density": "N/A",
      "aspect_ratio": "16 : 9"
    },
    "Apple iPad 10.2 (2020)": {
      "persist": "Apple iPad 10.2 (2020)",
      "operating_system": "iOS",
      "physical_size_in": "10.2",
      "physical_size_cm": "25.9",
      "px_width": "2160",
      "px_height": "1620",
      "device_width": "-",
      "ppi": "264",
      "px_density": "N/A",
      "aspect_ratio": "3 : 4"
    },
    "Apple iPad Pro 11": {
      "persist": "Apple iPad Pro 11",
      "operating_system": "iOS",
      "physical_size_in": "11.0",
      "physical_size_cm": "27.9",
      "px_width": "2388",
      "px_height": "1668",
      "device_width": "-",
      "ppi": "264",
      "px_density": "N/A",
      "aspect_ratio": "10 : 7"
    },
    "Apple iPad Pro 12.9": {
      "persist": "Apple iPad Pro 12.9",
      "operating_system": "iOS",
      "physical_size_in": "12.9",
      "physical_size_cm": "32.8",
      "px_width": "2732",
      "px_height": "2048",
      "device_width": "-",
      "ppi": "264",
      "px_density": "N/A",
      "aspect_ratio": "4 : 3"
    },
    "Apple iPad Mini (2019)": {
      "persist": "Apple iPad Mini (2019)",
      "operating_system": "iOS",
      "physical_size_in": "7.9",
      "physical_size_cm": "20.1",
      "px_width": "2048",
      "px_height": "1536",
      "device_width": "-",
      "ppi": "326",
      "px_density": "N/A",
      "aspect_ratio": "4 : 3"
    },
    "Apple iPad Pro 10.5": {
      "persist": "Apple iPad Pro 10.5",
      "operating_system": "iOS",
      "physical_size_in": "10.5",
      "physical_size_cm": "26.7",
      "px_width": "2224",
      "px_height": "1668",
      "device_width": "-",
      "ppi": "264",
      "px_density": "N/A",
      "aspect_ratio": "4 : 3"
    },
    "Apple iPhone 12": {
      "persist": "Apple iPhone 12",
      "operating_system": "iOS",
      "physical_size_in": "6.1",
      "physical_size_cm": "15.5",
      "px_width": "1170",
      "px_height": "2532",
      "device_width": "-",
      "ppi": "460",
      "px_density": "N/A",
      "aspect_ratio": "19 . 5 : 9"
    },
    "Apple iPhone 12 Mini": {
      "persist": "Apple iPhone 12 Mini",
      "operating_system": "iOS",
      "physical_size_in": "5.4",
      "physical_size_cm": "13.7",
      "px_width": "1080",
      "px_height": "2340",
      "device_width": "-",
      "ppi": "476",
      "px_density": "N/A",
      "aspect_ratio": "19 . 5 : 9"
    },
    "Apple iPhone 12 Pro": {
      "persist": "Apple iPhone 12 Pro",
      "operating_system": "iOS",
      "physical_size_in": "6.1",
      "physical_size_cm": "15.5",
      "px_width": "1170",
      "px_height": "2532",
      "device_width": "-",
      "ppi": "460",
      "px_density": "N/A",
      "aspect_ratio": "19 . 5 : 9"
    },
    "Apple iPhone 12 Pro Max": {
      "persist": "Apple iPhone 12 Pro Max",
      "operating_system": "iOS",
      "physical_size_in": "6.7",
      "physical_size_cm": "17.0",
      "px_width": "1284",
      "px_height": "2778",
      "device_width": "-",
      "ppi": "458",
      "px_density": "N/A",
      "aspect_ratio": "19 . 5 : 9"
    }
}

export const deviceNames = Object.keys(devices).sort();
